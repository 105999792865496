import './Footer.css';

const Footer = () => {
  return (
      <div className='footer'>
        Cette application est développée par{' '}
        <a href="https://skaneat.com" target="_blank" rel="noopener noreferrer">
          SkanEat
        </a>
        .
      </div>
  );
};

export default Footer;
