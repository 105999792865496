import React, { useEffect, useState, useCallback } from 'react';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import jsPDF from 'jspdf';
import './WeekInvoices.css';
import MonthInvoices from './MonthInvoices';

const WeekInvoices = () => {
  const [weeklyData, setWeeklyData] = useState([]);
  const [expandedWeek, setExpandedWeek] = useState(null);
  const [searchDate, setSearchDate] = useState(''); // État pour stocker la date recherchée

  const calculateWeeklyData = useCallback((commandes) => {
    const weeks = {};

    commandes.forEach((commande) => {
      const orderDate = new Date(commande.date.toDate());
      const startOfWeek = getStartOfWeek(orderDate);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6);

      const weekKey = `${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`;

      if (!weeks[weekKey]) {
        weeks[weekKey] = {
          revenue: 0,
          dishes: 0,
          details: [],
        };
      }

      weeks[weekKey].revenue += commande.total;
      weeks[weekKey].dishes += commande.items.length;
      weeks[weekKey].details.push(commande);
    });

    // Convertir l'objet en tableau, trier les semaines par date (du plus récent au plus ancien)
    return Object.entries(weeks)
      .map(([key, value]) => ({
        week: key,
        startDate: new Date(key.split(' - ')[0].split('/').reverse().join('-')), // Obtenir la date de début pour le tri
        ...value,
      }))
      .sort((a, b) => b.startDate - a.startDate); // Tri par date de début, du plus récent au plus ancien
  }, []);

  useEffect(() => {
    const fetchWeeklyData = async () => {
      const commandesSnapshot = await getDocs(collection(db, 'total_commande'));
      const commandes = commandesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const weeks = calculateWeeklyData(commandes);
      setWeeklyData(weeks);
    };

    fetchWeeklyData();
  }, [calculateWeeklyData]);

  const getStartOfWeek = (date) => {
    const day = date.getDay();
    const diff = day === 0 ? 6 : day - 1; // Dimanche = dernier jour
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - diff);
    startOfWeek.setHours(0, 0, 0, 0);
    return startOfWeek;
  };

  const formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;
  };

  const formatOrderDate = (date) => {
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;
  };

  const generatePDF = (week) => {
    const pdf = new jsPDF();
    pdf.setFontSize(16);
    pdf.text(`Rapport de la semaine : ${week.week}`, 10, 10);
    pdf.setFontSize(12);
    pdf.text(`Revenus totaux : ${week.revenue} €`, 10, 20);
    pdf.text(`Nombre de plats commandés : ${week.dishes}`, 10, 30);

    pdf.setFontSize(10);
    week.details
      .sort((a, b) => {
        const dateA = new Date(a.date.toDate());
        const dateB = new Date(b.date.toDate());
        return dateA - dateB;
      })
      .forEach((commande, index) => {
        const orderDate = new Date(commande.date.toDate());
        const formattedDate = formatOrderDate(orderDate);
        const y = 40 + index * 10;
        pdf.text(`Commande du ${formattedDate}: ${commande.total} € - ${commande.items.length} plats`, 10, y);
      });

    pdf.save(`Semaine_${week.week.replace(/\//g, '_')}.pdf`);
  };

  // Fonction de filtrage des semaines par date
  const filterByDate = (weeks) => {
    if (!searchDate) return weeks; // Si aucune date n'est saisie, on retourne toutes les semaines

    // Convertir la date de recherche en objet Date
    const [day, month, year] = searchDate.split('/').map(Number);
    const searchDateObj = new Date(year, month - 1, day);

    // Filtrer les semaines qui contiennent cette date
    return weeks.filter((week) => {
      const startOfWeek = new Date(week.startDate);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6);

      // Vérifier si la date recherchée est dans la semaine
      return searchDateObj >= startOfWeek && searchDateObj <= endOfWeek;
    });
  };

  return (
    <div className="week-invoices">
      <h1>Factures par semaine</h1>
      
      {/* Section de recherche */}
      <div className="search-section">
        <input
          type="text"
          value={searchDate}
          onChange={(e) => setSearchDate(e.target.value)}
          placeholder="Rechercher une semaine (ex: 01/12/2025)"
        />
      </div>
      
      <div className="weekly-buttons">
        {filterByDate(weeklyData).map((week, index) => (
          <div key={index} className="week-container">
            <button
              className="week-button"
              onClick={() => setExpandedWeek(expandedWeek === index ? null : index)}
            >
              {`Semaine ${week.week}`}
            </button>
            {expandedWeek === index && (
              <>
                <div className="modal-overlay" onClick={() => setExpandedWeek(null)} />
                <div className="week-details">
                  <button className="close-button" onClick={() => setExpandedWeek(null)}>×</button>
                  <h2>Détails de la semaine</h2>
                  <p><strong>Revenus totaux :</strong> {week.revenue} €</p>
                  <p><strong>Nombre de commandes :</strong> {week.dishes}</p>
                  <ul>
                    {week.details
                      .sort((a, b) => {
                        const dateA = new Date(a.date.toDate());
                        const dateB = new Date(b.date.toDate());
                        return dateA - dateB;
                      })
                      .map((commande, idx) => {
                        const orderDate = new Date(commande.date.toDate());
                        const formattedDate = formatOrderDate(orderDate);
                        return (
                          <li key={idx}>
                            Commande du {formattedDate} : {commande.total} € - {commande.items.length} plats
                          </li>
                        );
                      })}
                  </ul>
                  <button onClick={() => generatePDF(week)}>Télécharger le PDF</button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
      {<MonthInvoices />}
    </div>
  );
};

export default WeekInvoices;
