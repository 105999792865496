import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import './ComparaisonDate.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ComparaisonDate = () => {
  const [revenues, setRevenues] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [monthComparisonYear, setMonthComparisonYear] = useState(new Date().getFullYear());
  const [weekComparisonYear, setWeekComparisonYear] = useState(new Date().getFullYear());
  const [totalRevenue, setTotalRevenue] = useState({});
  const [weeklyRevenue, setWeeklyRevenue] = useState({});
  const [monthlyRevenue, setMonthlyRevenue] = useState({});

  useEffect(() => {
    const fetchRevenues = async () => {
      const commandesSnapshot = await getDocs(collection(db, "total_commande"));
      const commandes = commandesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRevenues(commandes);
    };
    fetchRevenues();
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    let monthsToShow = 12;
    if (selectedYear === currentYear) {
      monthsToShow = currentMonth + 1;
    } else if (selectedYear > currentYear) {
      monthsToShow = 1;
    }

    if (selectedMonth >= monthsToShow) {
      setSelectedMonth(monthsToShow - 1);
    }
  }, [selectedYear, selectedMonth]);

  const handleDaySelect = (date) => {
    if (selectedDays.includes(date)) {
      setSelectedDays(selectedDays.filter((day) => day !== date));
    } else {
      setSelectedDays([...selectedDays, date]);
    }
  };

  const handleWeekSelect = (week) => {
    if (selectedWeeks.includes(week)) {
      setSelectedWeeks(selectedWeeks.filter((w) => w !== week));
    } else {
      setSelectedWeeks([...selectedWeeks, week]);
    }
  };

  const handleMonthSelect = (month) => {
    if (selectedMonths.includes(month)) {
      setSelectedMonths(selectedMonths.filter((m) => m !== month));
    } else {
      setSelectedMonths([...selectedMonths, month]);
    }
  };

  const calculateTotalRevenue = () => {
    // Trier les dates sélectionnées
    const sortedDays = [...selectedDays].sort((a, b) => new Date(a) - new Date(b));
  
    const totals = sortedDays.map((day) => {
      const revenueForDay = revenues.filter((commande) => {
        const orderDate = new Date(commande.date.toDate());
        return orderDate.toLocaleDateString() === day;
      }).reduce((acc, curr) => acc + curr.total, 0);
      return revenueForDay;
    });
  
    const percentageChanges = totals.map((current, index) => {
      if (index === 0) return null; // Pas de précédent pour le premier jour
      return calculatePercentageChange(current, totals[index - 1]);
    });
  
    const labels = sortedDays;
    const data = totals;
  
    setTotalRevenue({
      labels,
      data,
      percentageChanges,
    });
  };  

  const calculateWeeklyRevenue = () => {
    // Trier les semaines par ordre croissant
    const sortedWeeks = [...selectedWeeks].sort((a, b) => a - b);
  
    const totals = sortedWeeks.map((week) => {
      const revenueForWeek = revenues.filter((commande) => {
        const orderDate = new Date(commande.date.toDate());
        const orderWeek = getWeekNumber(orderDate);
        const orderYear = orderDate.getFullYear();
        return orderWeek === week && orderYear === weekComparisonYear;
      }).reduce((acc, curr) => acc + curr.total, 0);
      return revenueForWeek;
    });
  
    const percentageChanges = totals.map((current, index) => {
      if (index === 0) return null; // Pas de précédent pour la première semaine
      return calculatePercentageChange(current, totals[index - 1]);
    });
  
    const labels = sortedWeeks.map((week) => `Semaine ${week} de ${weekComparisonYear}`);
    const data = totals;
  
    setWeeklyRevenue({
      labels,
      data,
      percentageChanges,
    });
  };  

  const calculateMonthlyRevenue = () => {
    // Trier les mois par ordre croissant
    const sortedMonths = [...selectedMonths].sort((a, b) => a - b);
  
    const totals = sortedMonths.map((month) => {
      const revenueForMonth = revenues.filter((commande) => {
        const orderDate = new Date(commande.date.toDate());
        return (
          orderDate.getMonth() === month &&
          orderDate.getFullYear() === monthComparisonYear
        );
      }).reduce((acc, curr) => acc + curr.total, 0);
      return revenueForMonth;
    });
  
    const percentageChanges = totals.map((current, index) => {
      if (index === 0) return null; // Pas de précédent pour le premier mois
      return calculatePercentageChange(current, totals[index - 1]);
    });
  
    const labels = sortedMonths.map((month) =>
      `${new Date(0, month).toLocaleString("default", { month: "long" })} de ${monthComparisonYear}`
    );
    const data = totals;
  
    setMonthlyRevenue({
      labels,
      data,
      percentageChanges,
    });
  };  

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value, 10));
  };

  const handleMonthComparisonYearChange = (event) => {
    setMonthComparisonYear(parseInt(event.target.value, 10));
  };

  const handleWeekComparisonYearChange = (event) => {
    setWeekComparisonYear(parseInt(event.target.value, 10));
  };  

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / (1000 * 60 * 60 * 24);
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  const renderCalendar = () => {
    const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1).getDay();
    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    const calendarDays = [];

    // Calculer le nombre correct de jours vides
    const emptyDays = firstDayOfMonth === 0 ? 0 : firstDayOfMonth;
    
    // Ajouter les jours vides avant le premier jour du mois
    for (let i = 0; i < emptyDays; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    // Ajouter les jours du mois
    for (let day = 1; day <= daysInMonth; day++) {
      const formattedDate = new Date(selectedYear, selectedMonth, day).toLocaleDateString();
      calendarDays.push(
        <button
          key={formattedDate}
          className={`calendar-day ${selectedDays.includes(formattedDate) ? "selected" : ""}`}
          onClick={() => handleDaySelect(formattedDate)}
        >
          {day}
        </button>
      );
    }

    return (
      <div className="calendar-wrapper">
        <div className="calendar-header">
          <span>Dim</span>
          <span>Lun</span>
          <span>Mar</span>
          <span>Mer</span>
          <span>Jeu</span>
          <span>Ven</span>
          <span>Sam</span>
        </div>
        <div className="days">
          {calendarDays}
        </div>
      </div>
    );
  };

  const renderWeeklyCalendar = () => {
    const totalWeeks = 52; // Nombre total de semaines dans une année
    const weekButtons = [];
    for (let week = 1; week <= totalWeeks; week++) {
      weekButtons.push(
        <button
          key={week}
          className={`calendar-week ${selectedWeeks.includes(week) ? "selected" : ""}`}
          onClick={() => handleWeekSelect(week)}
        >
          Semaine {week}
        </button>
      );
    }
    return <div className="weeks">{weekButtons}</div>;
  };  

  const renderMonthlyCalendar = () => {
    const monthButtons = [];
    for (let month = 0; month < 12; month++) {
      monthButtons.push(
        <button
          key={month}
          className={`calendar-month ${selectedMonths.includes(month) ? "selected" : ""}`}
          onClick={() => handleMonthSelect(month)}
        >
          {new Date(0, month).toLocaleString("default", { month: "long" })}
        </button>
      );
    }
    return monthButtons;
  };

  const renderMonthSelector = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // Détermine le nombre de mois à afficher
    let monthsToShow = 12;
    if (selectedYear === currentYear) {
      monthsToShow = currentMonth + 1;
    } else if (selectedYear > currentYear) {
      monthsToShow = 1;
    }

    return (
      <div className="month-selector">
        <div className="my-month-selector">
          <select 
            value={selectedMonth} 
            onChange={handleMonthChange}
            className="month-select"
          >
            {Array.from({ length: monthsToShow }, (_, i) => (
              <option key={i} value={i}>
                {new Date(0, i).toLocaleString("default", { month: "long" })}
              </option>
            ))}
          </select>
        </div>
        <div className="year-selector">
          <select
            value={selectedYear}
            onChange={handleYearChange}
            className="year-select"
          >
            {Array.from({ length: new Date().getFullYear() - 2023 }, (_, i) => new Date().getFullYear() - i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  const renderWeekComparisonYearSelector = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2023 }, (_, i) => currentYear - i);

    return (
      <div className="year-selector">
        <select
          value={weekComparisonYear}
          onChange={handleWeekComparisonYearChange}
          className="year-select"
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const renderMonthComparisonYearSelector = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 2023 }, (_, i) => currentYear - i);

    return (
      <div className="year-selector">
        <select
          value={monthComparisonYear}
          onChange={handleMonthComparisonYearChange}
          className="year-select"
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    );
  };  

  const createComparisonChart = (data) => {
    const maxRevenue = Math.max(...data.data);
    const maxLimit = maxRevenue;
  
    return (
      <div className="chart-container">
        <Bar
          data={{
            labels: data.labels,
            datasets: [
              {
                label: "Revenus",
                data: data.data,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: "Comparaison des Revenus",
              },
              tooltip: {
                callbacks: {
                  label: (tooltipItem) => {
                    const index = tooltipItem.dataIndex;
                    const percentage = data.percentageChanges[index];
                    const percentageText = percentage !== null
                      ? ` (${percentage.toFixed(1)}%)`
                      : "";
                    return `Revenu : ${tooltipItem.raw} €${percentageText}`;
                  },
                },
              },
            },
            scales: {
              x: {
                beginAtZero: true,
              },
              y: {
                beginAtZero: true,
                max: maxLimit,
              },
            },
          }}
        />
      </div>
    );
  };  

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return current === 0 ? 0 : 100; // Si précédent est 0, renvoie 100% ou 0%
    return ((current - previous) / previous) * 100;
  };  

  const resetDaySelection = () => {
    setSelectedDays([]);
    setTotalRevenue({});
  };
  
  const resetWeekSelection = () => {
    setSelectedWeeks([]);
    setWeeklyRevenue({});
  };
  
  const resetMonthSelection = () => {
    setSelectedMonths([]);
    setMonthlyRevenue({});
  };  

  return (
    <div className="comparaison-date">
      <h1>Comparer les Revenus</h1>
      
      {renderMonthSelector()}

      <div className="calendar">
        <h2>
          Calendrier de {new Date(selectedYear, selectedMonth).toLocaleString("default", {
            month: "long",
          })}{" "}
          {selectedYear}
        </h2>
        <div className="days">{renderCalendar()}</div>
      </div>

      {selectedDays.length > 0 && (
        <button onClick={calculateTotalRevenue} className="compare-button">
          Comparer par Jour
        </button>
      )}
      {totalRevenue.labels && createComparisonChart(totalRevenue)}
      {selectedDays.length > 0 && (
        <button onClick={resetDaySelection} className="reset-button">
            Réinitialiser
        </button>
        )}

        <div className="weekly-calendar-container">
          <div className="calendar">
            <h2>Calendrier Hebdomadaire</h2>
            {renderWeekComparisonYearSelector()}
            {renderWeeklyCalendar()}
          </div>
        </div>
        {selectedWeeks.length > 0 && (
        <button onClick={calculateWeeklyRevenue} className="compare-button">
            Comparer par Semaine
        </button>
        )}
      {weeklyRevenue.labels && createComparisonChart(weeklyRevenue)}
      {selectedWeeks.length > 0 && (
        <button onClick={resetWeekSelection} className="reset-button">
            Réinitialiser
        </button>
        )}

        <div className="calendar">
          <h2>Calendrier Mensuel</h2>
          {renderMonthComparisonYearSelector()} {/* Sélecteur d'année pour les mois */}
          <div className="months">{renderMonthlyCalendar()}</div>
        </div>
        {selectedMonths.length > 0 && (
        <button onClick={calculateMonthlyRevenue} className="compare-button">
            Comparer par Mois
        </button>
        )}
      {monthlyRevenue.labels && createComparisonChart(monthlyRevenue)}
      {selectedMonths.length > 0 && (
        <button onClick={resetMonthSelection} className="reset-button">
            Réinitialiser
        </button>
        )}
    </div>
  );
};

export default ComparaisonDate;
