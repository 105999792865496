import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { db } from '../../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import './admin.css';

const AddDishForm = ({ menuId }) => {
  const [nom, setNom] = useState('');
  const [prix, setPrix] = useState('');
  const [description, setDescription] = useState('');
  const [photo, setPhoto] = useState(null);
  const [categorie, setCategorie] = useState('');
  const [erreur, setErreur] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showSupplementsPopup, setShowSupplementsPopup] = useState(false);
  const [suppléments, setSuppléments] = useState({});
  const [selectedSuppléments, setSelectedSuppléments] = useState({});

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setShowSupplementsPopup(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const fetchSupplements = async () => {
      try {
        const supplementRef = doc(db, 'menu', menuId);
        const docSnapshot = await getDoc(supplementRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setSuppléments(data.suppléments || {});
          
          const initialSelected = Object.keys(data.suppléments || {}).reduce((acc, category) => {
            acc[category] = [];
            return acc;
          }, {});
          setSelectedSuppléments(initialSelected);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des suppléments:', error);
        setSuppléments({});
        setSelectedSuppléments({});
      }
    };

    fetchSupplements();
  }, [menuId]);

  const handleSupplementChange = (category, supplement) => {
    setSelectedSuppléments((prevSelected) => {
      const prevCategorySelection = prevSelected[category] || [];
  
      const newSelection = prevCategorySelection.includes(supplement)
        ? prevCategorySelection.filter(item => item !== supplement)
        : [...prevCategorySelection, supplement];
  
      return {
        ...prevSelected,
        [category]: newSelection
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!nom.trim() || !prix || !description.trim() || !categorie) {
      setErreur('Tous les champs sont requis, sauf la photo.');
      return;
    }
  
    if (isNaN(parseFloat(prix)) || parseFloat(prix) < 0) {
      setErreur('Le prix doit être un nombre positif.');
      return;
    }
  
    setErreur('');
    setUploading(true);
  
    try {
      let photoURL = '';
  
      if (photo) {
        const formData = new FormData();
        formData.append('file', photo);
        formData.append('upload_preset', 'menu_photos');
        formData.append('cloud_name', 'dyuyrwww1');
  
        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/dyuyrwww1/image/upload`,
          formData
        );
  
        if (!response.data.secure_url) {
          throw new Error("L'upload de l'image a échoué.");
        }
  
        photoURL = response.data.secure_url;
      }
  
      const idNom = nom.replace(/\s+/g, '_').toLowerCase();
  
      const updatedSelectedSupplements = Object.entries(selectedSuppléments).reduce(
        (acc, [category, selected]) => {
          if (selected.length > 0) {
            acc[category] = {
              items: selected,
              selectionType: suppléments[category]?.selectionType || "multiple",
            };
          }
          return acc;
        },
        {}
      );
  
      await setDoc(doc(db, `menu/${menuId}/${categorie}`, idNom), {
        nom: nom.trim(),
        prix: parseFloat(prix),
        description: description.trim(),
        photo: photoURL || null,
        categorie,
        actif: true,
        suppléments: updatedSelectedSupplements,
        createdAt: new Date(),
      });
  
      triggerPopup();
      resetForm();
      handleResetSupplements();
    } catch (error) {
      console.error('Erreur lors de l\'ajout du plat :', error.message);
      setErreur("Échec de l'ajout du plat. Vérifiez votre connexion ou Cloudinary.");
    } finally {
      setUploading(false);
    }
  };

  const resetForm = () => {
    setNom('');
    setPrix('');
    setDescription('');
    setPhoto(null);
    setCategorie('');
  };

  const triggerPopup = () => {
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 2000);
  };

  const handleResetSupplements = () => {
    const resetSelected = Object.keys(suppléments).reduce((acc, category) => {
      acc[category] = [];
      return acc;
    }, {});
    setSelectedSuppléments(resetSelected);
  };

  const handlePrixChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setPrix(value);
    }
    if (/^\d*\.?\d*$/.test(value)) {
      setPrix(value); 
    }
  };

  return (
    <div className="add-dish form-container">
      <h2>Ajouter un nouveau plat</h2>
      {erreur && <p style={{ color: 'red' }}>{erreur}</p>}
      <form onSubmit={handleSubmit}>
        <div className="dish-flex">
          <div>
            <label>Nom du plat :</label>
            <input
              type="text"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Prix (€) :</label>
            <input
              type="number"
              value={prix}
              onChange={handlePrixChange}
              min="0"
              step="0.01"
              required
            />
          </div>
        </div>
        <div>
          <label>Description :</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="dish-flex">
          <div>
            <label>Photo :</label>
            <input
              type="file"
              id="photo-upload"
              accept="image/*"
              onChange={(e) => setPhoto(e.target.files[0])}
            />
            <label htmlFor="photo-upload">
              {photo ? photo.name : "Sélectionner une photo"}
            </label>
          </div>
          <div>
            <label>Catégorie :</label>
            <select
              value={categorie}
              onChange={(e) => setCategorie(e.target.value)}
              required
            >
              <option value="">Sélectionnez une catégorie</option>
              <option value="entrees">Entrée</option>
              <option value="plats">Plat</option>
              <option value="desserts">Dessert</option>
              <option value="boissons">Boisson</option>
            </select>
          </div>
        </div>

        {/* Affichage des suppléments sélectionnés */}
        {Object.entries(selectedSuppléments).map(([category, items]) => 
          items.length > 0 && (
            <p key={category}>
              <strong>{category}:</strong> {items.join(', ')}
            </p>
          )
        )}

        {/* Popup de sélection des suppléments */}
        {showSupplementsPopup && (
          <div className="popup-selection" onClick={() => setShowSupplementsPopup(false)}>
            <div className="popup-selection-content" onClick={(e) => e.stopPropagation()}>
              <h3>Sélectionner des suppléments</h3>
              {Object.entries(suppléments).map(([category, categoryData]) => (
                categoryData.items && categoryData.items.length > 0 && (
                  <div key={category}>
                    <h4 className="title-center">
                      {category}
                      <span className="selection-type">
                        ({categoryData.selectionType === 'single' ? 'Choix unique' : 'Choix multiple'})
                      </span>
                    </h4>
                    {categoryData.items.map((supplement) => (
                      <div className="checkbox-container" key={supplement}>
                        <input
                          type='checkbox'
                          id={`supplement-${category}-${supplement}`}
                          name={categoryData.selectionType === 'single' ? `supplement-${category}` : undefined}
                          checked={selectedSuppléments[category]?.includes(supplement)}
                          onChange={() => handleSupplementChange(category, supplement)}
                        />
                        <label htmlFor={`supplement-${category}-${supplement}`}>
                          {supplement}
                        </label>
                      </div>
                    ))}
                  </div>
                )
              ))}
              <div className="popup-buttons">
                <button onClick={() => setShowSupplementsPopup(false)}>Valider</button>
                <button type='button' onClick={handleResetSupplements} className="reset-button">Réinitialiser</button>
              </div>
            </div>
          </div>
        )}

        <button type="button" className='addSlideBtn' onClick={() => setShowSupplementsPopup(true)}>
          Ajouter des suppléments
        </button>

        <button type="submit" disabled={uploading}>
          {uploading ? 'Téléchargement...' : 'Ajouter le plat'}
        </button>
      </form>

      {showPopup && (
        <div className="popup-confirmation fade-in">
          Plat ajouté avec succès !
        </div>
      )}
    </div>
  );
};

export default AddDishForm;