import React, { useEffect, useState, useCallback } from 'react';
import { db, auth } from '../../firebase'; // Assurez-vous d'importer auth
import { collection, getDocs, query, where, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import './WorkHours.css';
import * as XLSX from 'xlsx';
import emailjs from 'emailjs-com';

// Initialiser EmailJS avec votre clé publique
emailjs.init('T-pY9-uH4qzIB7dph');

const WorkHours = () => {
  const [employees, setEmployees] = useState([]);
  const [hoursWorked, setHoursWorked] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [employeeExtraHours, setEmployeeExtraHours] = useState({});
  const [adminEmail, setAdminEmail] = useState('');
  const [lastReportDate, setLastReportDate] = useState(null);

  // Configuration EmailJS - à remplacer par vos identifiants
  const EMAIL_SERVICE_ID = 'service_yuq4j4t';
  const EMAIL_TEMPLATE_ID = 'template_zynh1bf';

  const getRoleDisplayName = (role) => {
    switch (role) {
      case 'bar':
        return 'Barman/Barmaid';
      case 'server':
        return 'Serveur';
      case 'cook':
        return 'Cuisinier';
      default:
        return role;
    }
  };

  const convertDecimalToHoursAndMinutes = (decimal) => {
    const hours = Math.floor(decimal);
    const minutes = Math.round((decimal - hours) * 60);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return minutes === 0 ? `${hours}h` : `${hours}h${formattedMinutes}`;
  };

  // Fonction pour récupérer l'email de l'administrateur connecté
  const fetchAdminEmail = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setAdminEmail(currentUser.email);
    }
  };

  // Utiliser useCallback pour mémoriser la fonction sendMonthlyReport
  const sendMonthlyReport = useCallback(async () => {
    if (!adminEmail) {
      console.error("Email de l'administrateur non disponible");
      return;
    }

    try {
      const date = new Date();
      const monthName = date.toLocaleString('fr-FR', { month: 'long' });
      const year = date.getFullYear();
      
      // Générer le contenu HTML du tableau des heures
      let tableContent = `
        <h4>Veuillez trouver ci-joint le rapport des heures travaillées pour le mois de ${monthName} ${year}.</h4>
        <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
          <thead>
            <tr style="background-color: #f2f2f2;">
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Nom</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Rôle</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Heures Travaillées</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Heures Supplémentaires</th>
              <th style="border: 1px solid #ddd; padding: 8px; text-align: left;">Total</th>
            </tr>
          </thead>
          <tbody>
      `;
      
      // Ajouter chaque employé au tableau
      employees.forEach(employee => {
        const workedHours = hoursWorked[employee.id] || 0;
        const extraHours = parseFloat(employeeExtraHours[employee.id] || 0);
        const totalHours = workedHours + extraHours;
        
        tableContent += `
          <tr>
            <td style="border: 1px solid #ddd; padding: 8px;">${employee.firstName} ${employee.lastName}</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${getRoleDisplayName(employee.role)}</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${convertDecimalToHoursAndMinutes(workedHours)}</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${extraHours}</td>
            <td style="border: 1px solid #ddd; padding: 8px;">${convertDecimalToHoursAndMinutes(totalHours)}</td>
          </tr>
        `;
      });
      
      // Fermer le tableau
      tableContent += `
          </tbody>
        </table>
        <p style="margin-top: 20px;">Ce rapport a été généré automatiquement le ${new Date().toLocaleDateString('fr-FR')}.</p>
      `;
      
      const templateParams = {
        to_email: 'contact.skaneat@gmail.com',
        // to_email: adminEmail,
        to_name: 'SkanEat',
        subject: `Rapport des heures travaillées - ${monthName} ${year}`,
        message: tableContent
      };
      
      await emailjs.send(EMAIL_SERVICE_ID, EMAIL_TEMPLATE_ID, templateParams);
      alert('Rapport mensuel envoyé avec succès');
      
      // Mettre à jour la date du dernier rapport
      setLastReportDate(new Date());
    } catch (error) {
      console.error("Erreur lors de l'envoi du rapport mensuel:", error);
      alert("Erreur lors de l'envoi du rapport mensuel. Veuillez réessayer.");
    }
  }, [adminEmail, employees, hoursWorked, employeeExtraHours]);

  // Fonction pour générer le fichier Excel
  const generateExcelFile = useCallback(() => {
    // Créer les données pour le fichier Excel
    const excelData = employees.map(employee => ({
      'Nom': `${employee.firstName} ${employee.lastName}`,
      'Rôle': getRoleDisplayName(employee.role),
      'Heures Travaillées': convertDecimalToHoursAndMinutes(hoursWorked[employee.id] || 0),
      'Heures Supplémentaires': employeeExtraHours[employee.id] || 0,
      'Total Heures': convertDecimalToHoursAndMinutes((hoursWorked[employee.id] || 0) + parseFloat(employeeExtraHours[employee.id] || 0))
    }));

    // Créer un classeur Excel
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    
    // Ajouter la feuille au classeur
    XLSX.utils.book_append_sheet(workbook, worksheet, "Heures Travaillées");
    
    // Générer le fichier Excel
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    return blob;
  }, [employees, hoursWorked, employeeExtraHours]);

  // Au lieu d'utiliser cron, vérifier la date au chargement et à intervalles réguliers
  useEffect(() => {
    // Vérifier si nous sommes le premier jour du mois
    const checkAndSendMonthlyReport = () => {
      const now = new Date();
      const isFirstDayOfMonth = now.getDate() === 1;
      
      // Si c'est le premier jour du mois et qu'aucun rapport n'a été envoyé aujourd'hui
      if (isFirstDayOfMonth && (!lastReportDate || lastReportDate.getDate() !== 1 || lastReportDate.getMonth() !== now.getMonth())) {
        sendMonthlyReport();
      }
    };

    // Vérifier au chargement du composant
    checkAndSendMonthlyReport();
    
    // Vérifier toutes les heures (pour ne pas manquer le changement de jour)
    const intervalId = setInterval(checkAndSendMonthlyReport, 60 * 60 * 1000);
    
    return () => clearInterval(intervalId);
  }, [lastReportDate, sendMonthlyReport]);

  useEffect(() => {
    const fetchEmployeesAndSchedules = async () => {
      // Récupérer les employés
      const employeesCollection = collection(db, 'identifications');
      const employeesQuery = query(employeesCollection, where('role', '!=', 'admin'));
      const employeesSnapshot = await getDocs(employeesQuery);
  
      const employeesList = employeesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      setEmployees(employeesList);
  
      // Configurer un listener temps réel pour les horaires
      const schedulesCollection = collection(db, 'horaires');
      const unsubscribe = onSnapshot(schedulesCollection, (snapshot) => {
        const schedulesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        const schedulesData = schedulesList.reduce((acc, schedule) => {
          acc[schedule.employeeId] = schedule;
          return acc;
        }, {});
  
        // Calculer les heures travaillées et heures supplémentaires
        calculateHoursWorked(employeesList, schedulesData);
        fetchEmployeeExtraHours(schedulesList);
      });
  
      // Récupérer l'email de l'administrateur
      fetchAdminEmail();
  
      // Nettoyage du listener lors du démontage du composant
      return () => unsubscribe();
    };

    const fetchEmployeeExtraHours = (schedulesList) => {
      const extraHoursData = schedulesList.reduce((acc, schedule) => {
        acc[schedule.employeeId] = schedule.heure_supp || 0;
        return acc;
      }, {});
      setEmployeeExtraHours(extraHoursData);
    };

    const calculateHoursWorked = (employeesList, schedulesData) => {
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();
      const currentDay = today.getDate();
      const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
      const employeeHours = employeesList.reduce((acc, employee) => {
        const schedule = schedulesData[employee.id];
        if (!schedule) return acc;
    
        let totalHours = 0;
    
        for (let day = 1; day <= currentDay; day++) {
          const currentDate = new Date(currentYear, currentMonth, day);
          const dayName = dayNames[currentDate.getDay()];
    
          const workHours = schedule.schedule?.[dayName];
          if (workHours && workHours !== 'Ne travaille pas') {
            const timeRanges = workHours.split(' / '); // Diviser les plages horaires
    
            // Calcul des heures pour chaque plage
            const dayTotal = timeRanges.reduce((rangeTotal, range) => {
              const [start, end] = range.split(' - ');
              const [startHour, startMinute] = start.split(':').map(Number);
              const [endHour, endMinute] = end.split(':').map(Number);
    
              const startDate = new Date(currentYear, currentMonth, day, startHour, startMinute);
              const endDate = new Date(currentYear, currentMonth, day, endHour, endMinute);
    
              const now = new Date();
              if (endDate > now) {
                if (startDate <= now) {
                  const diffInMinutes = (now - startDate) / (1000 * 60);
                  return rangeTotal + diffInMinutes / 60;
                }
                return rangeTotal;
              }
    
              const diffInMinutes = (endDate - startDate) / (1000 * 60);
              return rangeTotal + diffInMinutes / 60;
            }, 0);
    
            totalHours += dayTotal;
          }
        }
    
        acc[employee.id] = totalHours;
        return acc;
      }, {});
    
      setHoursWorked(employeeHours);
      setIsLoading(false);
    };    

    fetchEmployeesAndSchedules();
  }, []);

  const handleDeleteExtraHours = async (employeeId) => {
    const scheduleRef = doc(db, 'horaires', employeeId);
    await updateDoc(scheduleRef, {
      heure_supp: 0,
    });

    alert('Heures supplémentaires supprimées.');
    setEmployeeExtraHours((prevState) => ({
      ...prevState,
      [employeeId]: 0,
    }));
  };

  const handleEditExtraHours = async (employeeId) => {
    const newExtraHours = parseFloat(employeeExtraHours[employeeId]);

    if (isNaN(newExtraHours)) {
      alert('Veuillez entrer un nombre valide pour les heures supplémentaires.');
      return;
    }

    const scheduleRef = doc(db, 'horaires', employeeId);

    try {
      await updateDoc(scheduleRef, {
        heure_supp: newExtraHours,
      });

      alert('Heures supplémentaires modifiées avec succès.');
      setEmployeeExtraHours((prevState) => ({
        ...prevState,
        [employeeId]: newExtraHours,
      }));
    } catch (error) {
      console.error('Erreur lors de la modification des heures supplémentaires :', error);
    }
  };

  if (isLoading) {
    return <div>
      <div className="dot-spinner">
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
      </div>
    </div>;
  }

  return (
    <div>
      <h2>Heures travaillées depuis le début du mois</h2>
      <div className="work-table-container">
        <table>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Rôle</th>
              <th>Total heures</th>
              <th>Heures Supplémentaires</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee) => (
              <tr key={employee.id}>
                <td data-label="Nom">{employee.firstName} {employee.lastName}</td>
                <td data-label="Rôle">{getRoleDisplayName(employee.role)}</td>
                <td data-label="Total heures">{convertDecimalToHoursAndMinutes(hoursWorked[employee.id] || 0)}</td>
                <td data-label="Heures Supplémentaires">
                  <input
                    type="number"
                    value={employeeExtraHours[employee.id] || ''}
                    onChange={(e) => setEmployeeExtraHours((prevState) => ({
                      ...prevState,
                      [employee.id]: e.target.value,
                    }))}
                    placeholder="Heures supplémentaires"
                  />
                </td>
                <td data-label="Actions" className='actions-button-hour'>
                  <button onClick={() => handleEditExtraHours(employee.id)}>Modifier</button>
                  <button className='delete-table-hour' onClick={() => handleDeleteExtraHours(employee.id)}>Supprimer</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <div className="button-container">
        <button 
          className="send-report-button" 
          onClick={sendMonthlyReport}
        >
          Envoyer les heures par email
        </button>
        
        <button 
          className="download-excel-button" 
          onClick={() => {
            const blob = generateExcelFile();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'heures-travaillees.xlsx';
            a.click();
            window.URL.revokeObjectURL(url);
          }}
        >
          Télécharger en Excel
        </button>
      </div>
    </div>
  );
};

export default WorkHours;
