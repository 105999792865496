import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Import your Firestore database
import { collection, getDocs } from 'firebase/firestore';
import './Avis.css';

const Avis = () => {
  const [avis, setAvis] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Fetch reviews from the Firestore collection
    const fetchAvis = async () => {
      const querySnapshot = await getDocs(collection(db, 'satisfaction'));
      const avisList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAvis(avisList);

      // Collect all feedbacks
      const feedbackList = avisList
        .filter(review => review.feedback !== "")
        .map(review => ({
          text: review.feedback,
          date: review.date?.toDate() || new Date() // Conversion de la date Firestore
        }));
      setFeedbacks(feedbackList);
    };

    fetchAvis();
  }, []);

  // Function to calculate the average of an array of numbers
  const calculateAverage = (values) => {
    if (values.length === 0) return 0;
    const sum = values.reduce((acc, val) => acc + val, 0);
    return (sum / values.length).toFixed(1); // 1 decimal point
  };

  // Extract ratings for application, service, and plats
  const applicationRatings = avis.map(review => review.application);
  const serviceRatings = avis.map(review => review.service);
  const platsRatings = avis.map(review => review.plats);

  // Calculate averages
  const averageApplication = calculateAverage(applicationRatings);
  const averageService = calculateAverage(serviceRatings);
  const averagePlats = calculateAverage(platsRatings);

  // Global average (average of all three categories)
  const globalAverage = calculateAverage([
    ...applicationRatings,
    ...serviceRatings,
    ...platsRatings
  ]);

  return (
    <div className="avis-container">
      <h2>Résumé des Avis</h2>

      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${(averageApplication / 5) * 100}%` }}
        ></div>
      </div>
      <p className='avarage-message'>Moyenne des notes de l'application : {averageApplication} / 5</p>

      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${(averageService / 5) * 100}%` }}
        ></div>
      </div>
      <p className='avarage-message'>Moyenne des notes du service : {averageService} / 5</p>

      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${(averagePlats / 5) * 100}%` }}
        ></div>
      </div>
      <p className='avarage-message'>Moyenne des notes des plats : {averagePlats} / 5</p>

      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${(globalAverage / 5) * 100}%` }}
        ></div>
      </div>
      <p className='avarage-message'>Moyenne globale : {globalAverage} / 5</p>

      <div className="feedbacks-container">
        <h3>Feedbacks</h3>
        {feedbacks.length > 0 ? (
          <ul>
            {feedbacks.map((feedback, index) => (
              <li key={index}>
                <span className="feedback-text">{feedback.text}</span>
                <span className="feedback-date">
                  {isMobile 
                    ? feedback.date.toLocaleDateString('fr-FR')
                    : feedback.date.toLocaleDateString('fr-FR', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                      })
                  }
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p>Aucun feedback disponible.</p>
        )}
      </div>
    </div>
  );
};

export default Avis;
