import React, { useEffect, useState, useCallback } from 'react';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import jsPDF from 'jspdf';
import './MonthInvoices.css';

const MonthInvoices = () => {
  const [monthlyData, setMonthlyData] = useState([]);
  const [expandedMonth, setExpandedMonth] = useState(null);
  const [searchMonth, setSearchMonth] = useState(''); // État pour stocker le mois recherché

  // Utilisation de useCallback pour stabiliser la fonction calculateMonthlyData
  const calculateMonthlyData = useCallback((commandes) => {
    const months = {};

    commandes.forEach((commande) => {
      const orderDate = new Date(commande.date.toDate());
      const monthKey = `${orderDate.getMonth() + 1}/${orderDate.getFullYear()}`;

      if (!months[monthKey]) {
        months[monthKey] = {
          revenue: 0,
          dishes: 0,
          details: [],
        };
      }

      months[monthKey].revenue += commande.total;
      months[monthKey].dishes += commande.items.length;
      months[monthKey].details.push(commande);
    });

    // Convertir l'objet en tableau, ajouter des dates et trier globalement par mois
    return Object.entries(months)
      .map(([key, value]) => {
        const [month, year] = key.split('/').map(Number);
        const startDate = new Date(year, month - 1); // Date pour trier les mois
        return {
          month: key,
          startDate,
          ...value,
        };
      })
      .sort((a, b) => b.startDate - a.startDate); // Tri par date de début du mois
  }, []);

  useEffect(() => {
    const fetchMonthlyData = async () => {
      const commandesSnapshot = await getDocs(collection(db, 'total_commande'));
      const commandes = commandesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const months = calculateMonthlyData(commandes);
      setMonthlyData(months);
    };

    fetchMonthlyData();
  }, [calculateMonthlyData]);

  const formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;
  };

  const generatePDF = (month) => {
    const pdf = new jsPDF();
    pdf.setFontSize(16);
    pdf.text(`Rapport du mois : ${month.month}`, 10, 10);
    pdf.setFontSize(12);
    pdf.text(`Revenus totaux : ${month.revenue} €`, 10, 20);
    pdf.text(`Nombre de plats commandés : ${month.dishes}`, 10, 30);

    pdf.setFontSize(10);
    month.details
      .sort((a, b) => {
        const dateA = new Date(a.date.toDate());
        const dateB = new Date(b.date.toDate());
        return dateA - dateB; // Tri du plus ancien au plus récent
      })
      .forEach((commande, index) => {
        const orderDate = new Date(commande.date.toDate());
        const formattedDate = formatDate(orderDate);
        const y = 40 + index * 10;
        pdf.text(`Commande du ${formattedDate}: ${commande.total} € - ${commande.items.length} plats`, 10, y);
      });

    pdf.save(`Mois_${month.month.replace(/\//g, '_')}.pdf`);
  };

  // Fonction de filtrage des mois par date
  const filterByMonth = (months) => {
    if (!searchMonth) return months; // Si aucune date n'est saisie, on retourne tous les mois

    // Vérifier que la date saisie est au bon format
    const regex = /^\d{2}\/\d{4}$/;
    if (!regex.test(searchMonth)) return []; // Si le format n'est pas valide, on retourne un tableau vide

    const [month, year] = searchMonth.split('/').map(Number);

    // Filtrer les mois qui correspondent à la recherche
    return months.filter((monthData) => {
      const [monthNumber, yearNumber] = monthData.month.split('/').map(Number);
      return monthNumber === month && yearNumber === year;
    });
  };

  return (
    <div className="month-invoices">
      <h1>Factures par mois</h1>
      
      {/* Section de recherche */}
      <div className="search-section">
        <input
          type="text"
          value={searchMonth}
          onChange={(e) => setSearchMonth(e.target.value)}
          placeholder="Rechercher un mois (ex: 12/2025)"
        />
      </div>
      
      <div className="monthly-buttons">
        {filterByMonth(monthlyData).map((month, index) => (
          <div key={index} className="month-container">
            <button
              className="month-button"
              onClick={() => setExpandedMonth(expandedMonth === index ? null : index)}
            >
              {`Mois ${month.month}`}
            </button>
            {expandedMonth === index && (
              <>
                <div className="modal-overlay" onClick={() => setExpandedMonth(null)} />
                <div className="month-details">
                  <button className="close-button" onClick={() => setExpandedMonth(null)}>×</button>
                  <h2>Détails du mois</h2>
                  <p><strong>Revenus totaux :</strong> {month.revenue} €</p>
                  <p><strong>Nombre de commandes :</strong> {month.dishes}</p>
                  <ul>
                    {month.details
                      .sort((a, b) => {
                        const dateA = new Date(a.date.toDate());
                        const dateB = new Date(b.date.toDate());
                        return dateA - dateB; // Tri du plus ancien au plus récent
                      })
                      .map((commande, idx) => {
                        const orderDate = new Date(commande.date.toDate());
                        const formattedDate = formatDate(orderDate);
                        return (
                          <li key={idx}>
                            Commande du {formattedDate} : {commande.total} € - {commande.items.length} plats
                          </li>
                        );
                      })}
                  </ul>
                  <button onClick={() => generatePDF(month)}>Télécharger le PDF</button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthInvoices;
