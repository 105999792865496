import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, query, getDocs, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { ReactComponent as PanierIcon } from '../../assets/panier.svg';
import './Menu.css';
import { FaPowerOff, FaArrowLeft } from 'react-icons/fa';
import { ReactComponent as MinusIcon } from '../../assets/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import Footer from '../Footer';

const Menu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [commentaire, setCommentaire] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const isFromResume = location.state?.from === '/resume';

  const handleBackOrReset = () => {
    if (isFromResume) {
      navigate('/resume');
    } else {
      setShowLogoutConfirm(true);
    }
  };
  
  const getCategoryFromUrl = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('category') || 'entrees'; // Retourne 'entrees' par défaut
  }, []);

  const [selectedCategory, setSelectedCategory] = useState('entrees');
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState(() => {
    // Charger le panier depuis localStorage lors de l'initialisation
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [isPopupVisible, setIsPopupVisible] = useState(false); // État pour la popup de sélection de quantité
  const [selectedProduct, setSelectedProduct] = useState(null); // Produit sélectionné pour ajout
  const [quantity, setQuantity] = useState(1); // Quantité sélectionnée
  const [successMessage, setSuccessMessage] = useState(''); // Message de succès
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // État pour la popup de succès
  const [isOrderPopupVisible, setIsOrderPopupVisible] = useState(false); // État pour la popup de commande
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false); // Ajouter cet état
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false); // État pour la popup de confirmation de suppression
  const [productToRemove, setProductToRemove] = useState(null); // Produit à supprimer

  const menuId = "ZifkqWN99oRgRNM0Nisz"; // Exemple de menuId, modifiez-le en fonction de votre logique
  
  //useEffect(() => {
    // Réinitialise le panier à chaque arrivée sur la page
    //setCart([]); // Réinitialiser l'état du panier
    //localStorage.removeItem('cart'); // Réinitialiser le panier dans le localStorage
  //}, []);  // Ce useEffect se déclenche une seule fois lors du premier rendu du composant    

  const confirmLogout = async () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  
    if (userInfo && userInfo.id) {
      try {
        const clientRef = doc(db, 'clients', userInfo.id);
        await deleteDoc(clientRef); // Supprime complètement le document Firebase
        console.log("Données utilisateur supprimées de la base.");
      } catch (error) {
        console.error("Erreur lors de la suppression des données utilisateur :", error);
      }
    }
  
    localStorage.removeItem('userInfo'); // Supprime l'entrée complète de localStorage
    navigate('/');
  };

  const fetchProducts = async (category) => {
    const productsCollection = collection(db, `menu/${menuId}/${category}`);
    const q = query(productsCollection);
    const querySnapshot = await getDocs(q);
    const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setProducts(items);
  };

  useEffect(() => {
    fetchProducts(selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    const category = getCategoryFromUrl();
    setSelectedCategory(category);
  }, [getCategoryFromUrl]);

  const handleSupplementChange = (category, supplement, selectionType) => {
    setSelectedSupplements((prevSupplements) => {
      // Si "Aucun" est sélectionné
      if (supplement === "Aucun") {
        return prevSupplements.filter((item) => item.category !== category);
      }
  
      if (selectionType === "single") {
        // Remplace tout choix existant de cette catégorie par le nouveau choix
        return [
          ...prevSupplements.filter((item) => item.category !== category),
          { category, supplement },
        ];
      } else {
        // Gestion des checkbox (ajout ou suppression)
        const existingIndex = prevSupplements.findIndex(
          (item) => item.category === category && item.supplement === supplement
        );
    
        if (existingIndex !== -1) {
          return prevSupplements.filter(
            (item) => !(item.category === category && item.supplement === supplement)
          );
        } else {
          // Si on ajoute un nouveau supplément, on s'assure de retirer "Aucun" s'il était sélectionné
          return [
            ...prevSupplements.filter(item => 
              !(item.category === category && item.supplement === "Aucun")
            ),
            { category, supplement }
          ];
        }
      }
    });
  };  

  const handleAddToCart = (product) => {
    setSelectedProduct(product);
    setIsPopupVisible(true);
    setErrorMessage(false);
    setSelectedSupplements([]);
  };

  const handleAddQuantity = () => {
    // Vérification des suppléments obligatoires (isRequired === true)
    const missingRequiredSupplements = selectedProduct.suppléments && 
        Object.entries(selectedProduct.suppléments).some(([category, data]) => {
            if (data.isRequired && !selectedSupplements.some(sup => sup.category === category)) {
                return true;
            }
            return false;
        });

    if (missingRequiredSupplements) {
        setErrorMessage("Le choix d'un supplément dans cette catégorie est obligatoire.");
        return; // Arrêter l'ajout au panier si un supplément obligatoire est manquant
    }

    // Vérification du nombre maximal de suppléments pour les catégories "multiple"
    const exceedingMaxSupplements = selectedProduct.suppléments && 
        Object.entries(selectedProduct.suppléments).some(([category, data]) => {
            if (data.selectionType === "multiple" && data.isRequired) {
                const selectedInCategory = selectedSupplements.filter(sup => sup.category === category);
                if (selectedInCategory.length > data.maxElement) {
                    setErrorMessage(`Le nombre de suppléments dans "${category}" est limité à ${data.maxElement}.`);
                    return true;
                }
            }
            return false;
        });

    if (exceedingMaxSupplements) {
        return; // Arrêter l'ajout au panier si le nombre maximal est dépassé
    }

    // Si toutes les vérifications sont réussies, ajouter au panier
    setCart(prevCart => {
        const cartItemId = selectedSupplements.length > 0
            ? `${selectedProduct.nom}-${selectedSupplements.map(s => s.supplement).sort().join('-')}`
            : selectedProduct.nom;

        const existingProductIndex = prevCart.findIndex(item => item.cartItemId === cartItemId);

        const groupedSupplements = selectedSupplements.reduce((acc, { category, supplement }) => {
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(supplement);
            return acc;
        }, {});

        const supplementsText = Object.entries(groupedSupplements)
            .map(([category, supplements]) => `${category}: ${supplements.join(', ')}`)
            .join(', ');

        const finalSupplementsText = supplementsText ? ` (${supplementsText})` : '';

        let supplementPrice = 0;
        selectedSupplements.forEach(supplement => {
            const supplementMatch = supplement.supplement.match(/-\s*(\d+,\d{1,2})€/);
            if (supplementMatch) {
                supplementPrice += parseFloat(supplementMatch[1].replace(',', '.'));
            }
        });

        const updatedProduct = {
            ...selectedProduct,
            nom: selectedProduct.nom + finalSupplementsText,
            quantity,
            supplements: selectedSupplements,
            prix: selectedProduct.prix + supplementPrice,
            cartItemId
        };

        let updatedCart;
        if (existingProductIndex !== -1) {
            updatedCart = [...prevCart];
            updatedCart[existingProductIndex] = {
                ...updatedCart[existingProductIndex],
                quantity: updatedCart[existingProductIndex].quantity + quantity,
            };
        } else {
            updatedCart = [...prevCart, updatedProduct];
        }

        localStorage.setItem('cart', JSON.stringify(updatedCart));
        return updatedCart;
    });

    setSuccessMessage(`${selectedProduct.nom} a été ajouté au panier.`);
    setShowSuccessPopup(true);
    setIsPopupVisible(false);
    setQuantity(1);
    setSelectedSupplements([]);
    
    setTimeout(() => {
        setShowSuccessPopup(false);
    }, 2000);
  };

  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => total + item.prix * item.quantity, 0).toFixed(2);
  };  

  const handleOrder = async () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const commandeDetails = cart.map(item => ({
        nom: item.nom,
        quantity: item.quantity,
        prix: item.prix,
        supplements: item.supplements,
        categorie: item.categorie
      }));
  
      const commandeRef = doc(collection(db, `clients/${userInfo.id}/commande`));
      await setDoc(commandeRef, {
        items: commandeDetails,
        total: calculateTotalPrice(),
        date: new Date(),
        statut: "Initialisée",
        commentaire: commentaire || null // Ajouter le commentaire s'il existe
      });
  
      console.log('Commande ajoutée avec succès.');
      alert('Votre commande a été envoyée avec succès.');
  
      setCart([]);
      localStorage.removeItem('cart');
      setCommentaire(''); // Réinitialiser le commentaire
      navigate('/resume');
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la commande: ', error);
      alert('Erreur lors de l\'ajout de la commande.');
    }
  
    setIsOrderPopupVisible(false);
  };  

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    // Mettre à jour l'URL avec la nouvelle catégorie
    navigate(`/menu?category=${category}`, { replace: true });
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Mettre à jour la catégorie si l'URL change
  useEffect(() => {
    const category = getCategoryFromUrl();
    if (category) {
      setSelectedCategory(category);
    }
  }, [getCategoryFromUrl, location.search]);

  const handleUpdateQuantity = (itemToUpdate, newQuantity) => {
    if (newQuantity <= 0) {
      setProductToRemove(itemToUpdate);
      setShowRemoveConfirm(true);
      return;
    }

    setCart(prevCart => {
      const updatedCart = prevCart.map(item =>
        item.cartItemId === itemToUpdate.cartItemId
          ? { ...item, quantity: newQuantity }
          : item
      );
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  const confirmRemove = () => {
    setCart(prevCart => {
      const updatedCart = prevCart.filter(item => item.cartItemId !== productToRemove.cartItemId);
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      return updatedCart;
    });
    setShowRemoveConfirm(false);
    setProductToRemove(null);
  };

  const cancelRemove = () => {
    setShowRemoveConfirm(false);
  };

  return (
    <div>
      <header className='menu-header'>
        <h1 className='menu-first-title'>Notre Menu</h1>
        <nav className='menu-nav'>
          <ul className="menu-nav-list">
            <li onClick={() => handleCategoryClick('entrees')} className="menu-nav-item">Entrées</li>
            <li onClick={() => handleCategoryClick('plats')} className="menu-nav-item">Plats</li>
            <li onClick={() => handleCategoryClick('desserts')} className="menu-nav-item">Desserts</li>
            <li onClick={() => handleCategoryClick('boissons')} className="menu-nav-item">Boissons</li>
          </ul>
        </nav>
        <button className="logout-btn" onClick={handleBackOrReset}>
          {isFromResume ? <FaArrowLeft /> : <FaPowerOff />}
        </button>
        <div className="cart-logo-container" onClick={() => {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
        }}>
            <PanierIcon className='cart-logo' />
            {cart.length > 0 && <span className="cart-count">{cart.length}</span>}
        </div>
      </header>
      <main>
        <h2 className="menu-title">Faites votre choix parmi nos spécialités !</h2>
        <div className="product-list">
          {products.length > 0 ? (
            products.map(product => (
              <div key={product.id} className={`product-item ${product.actif ? '' : 'inactive'}`}>
                <h3>{product.nom}</h3>
                <p className='price'>Prix: {product.prix.toFixed(2)} €</p>
                <p className='description'>Description: {product.description}</p>
                <div className="product-photo-container">
                  {product.photo ? (
                    <img src={product.photo} alt={product.nom} className="product-photo" />
                  ) : (
                    <div className="no-image-text">Pas encore de visuel disponible</div>
                  )}
                </div>
                <button 
                  onClick={() => handleAddToCart(product)} 
                  disabled={!product.actif} // Désactiver si le produit n'est pas actif
                  className={product.actif ? 'add-to-cart-btn' : 'add-to-cart-btn disabled'} // Appliquer un style désactivé
                  >
                  {product.actif ? 'Ajouter' : 'Indisponible'}
                </button>
                <hr />
              </div>
            ))
          ) : (
            <p>Aucun produit disponible dans cette catégorie.</p>
          )}
        </div>

        {/* Affichage du panier */}
        <div className="menu-cart-section">
          <h2>Votre Panier</h2>
            {cart.length > 0 ? (
              cart.map(item => (
                <div key={item.id} className="menu-cart-item">
                  <div className="menu-cart-item-info">
                    <p>
                      {item.nom} 
                    </p>
                    <p className="menu-cart-item-price">{(item.prix * item.quantity).toFixed(2)} €</p>
                  </div>
                  <div className="menu-cart-quantity-controls">
                    <button 
                      className="quantity-btn"
                      onClick={() => handleUpdateQuantity(item, item.quantity - 1)}
                    >
                      -
                    </button>
                    <span className="quantity-display">{item.quantity}</span>
                    <button 
                      className="quantity-btn"
                      onClick={() => handleUpdateQuantity(item, item.quantity + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
              ))
          ) : (
            <p className='no-item'>Votre panier est vide.</p>
          )}
          <h3>Total: {calculateTotalPrice()} €</h3>
          <button onClick={() => setIsOrderPopupVisible(true)} disabled={cart.length === 0} className="order-btn">Commander</button>
        </div>

        {/* Popup de commande */}
        {isOrderPopupVisible && (
          <>
            <div className="menu-popup-overlay" />
            <div className="menu-popup">
              <h3>Confirmation de commande</h3>
              <p>Voulez-vous confirmer votre commande ?</p>
              <textarea
                placeholder="Ajoutez un commentaire (optionnel)..."
                value={commentaire}
                onChange={(e) => setCommentaire(e.target.value)}
                className="commentaire-textarea"
              />
              <div className="menu-popup-buttons">
                <button onClick={handleOrder}>Commander</button>
                <button onClick={() => setIsOrderPopupVisible(false)}>Annuler</button>
              </div>
            </div>
          </>
        )}

        {/* Popup pour sélectionner la quantité et les suppléments */}
        {isPopupVisible && (
          <>
            <div className="menu-popup-overlay" />
            <div className="menu-popup">
              <h3>Choisissez la quantité pour {selectedProduct.nom}</h3>
              <div className="quantity-controls-popup">
                <button 
                  className="quantity-btn-popup"
                  onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}
                >
                  <MinusIcon />
                </button>
                <span className="quantity-display-popup">{quantity}</span>
                <button 
                  className="quantity-btn-popup"
                  onClick={() => setQuantity(quantity + 1)}
                >
                  <PlusIcon />
                </button>
              </div>
              
              {/* Affichage des suppléments disponibles pour ce produit */}
              {selectedProduct.suppléments && Object.keys(selectedProduct.suppléments).length > 0 && (
                <div className="supplements-container-popup">
                  {Object.entries(selectedProduct.suppléments).map(([category, data]) => (
                    <div key={category} className="supplement-category-box">
                      <h4 className="supplement-category-title">{category} <br />(maximum {data.maxElement})</h4>
                      <div className="supplement-items">
                        <label className="container-popup">
                          <input
                            type="radio"
                            name={category}
                            value="Aucun"
                            checked={!selectedSupplements.some(sup => sup.category === category)}
                            onChange={() => handleSupplementChange(category, "Aucun", data.selectionType)}
                          />
                          <div className="icon-text">
                            <svg viewBox="0 0 64 64" height="1.2em" width="1.2em">
                              <path
                                d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                                pathLength="575.0541381835938"
                                className="path-popup"
                              />
                            </svg>
                            <span>Aucun</span>
                          </div>
                        </label>
                        {data.items.map((item, index) => (
                          <label key={index} className="container-popup">
                            <input
                              type={data.selectionType === "single" ? "radio" : "checkbox"}
                              name={data.selectionType === "single" ? category : `${category}-${index}`}
                              value={item}
                              checked={selectedSupplements.some(
                                (sup) => sup.category === category && sup.supplement === item
                              )}
                              onChange={() => handleSupplementChange(category, item, data.selectionType)}
                            />
                            <div className="icon-text">
                              <svg viewBox="0 0 64 64" height="1.2em" width="1.2em">
                                <path
                                  d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                                  pathLength="575.0541381835938"
                                  className="path-popup"
                                />
                              </svg>
                              <span>{item}</span>
                            </div>
                          </label>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              
              <div className="menu-popup-buttons">
                <button onClick={handleAddQuantity}>Ajouter</button>
                <button onClick={() => setIsPopupVisible(false)}>Annuler</button>
              </div>
              {errorMessage && <p className="error-message-supplement">{errorMessage}</p>}
            </div>
          </>
        )}

        {/* Message de succès */}
        {showSuccessPopup && (
          <>
            <div className="menu-popup-overlay" />
            <div className="menu-popup-confirmation">
              <p>{successMessage}</p>
            </div>
          </>
        )}

        {/* Popup de confirmation de déconnexion */}
        {showLogoutConfirm && (
          <>
            <div className="menu-popup-overlay" />
            <div className="menu-popup">
              <h3>Confirmation de déconnexion</h3>
              <p>Êtes-vous sûr de vouloir vous déconnecter ?</p>
              <div className="menu-popup-buttons">
                <button onClick={confirmLogout}>Confirmer</button>
                <button onClick={() => setShowLogoutConfirm(false)}>Annuler</button>
              </div>
            </div>
          </>
        )}

        {/* Popup de confirmation de suppression */}
        {showRemoveConfirm && (
          <>
            <div className="menu-popup-overlay" />
            <div className="menu-popup">
              <h3>Confirmation de suppression</h3>
              <p>Êtes-vous sûr de vouloir supprimer cet élément du panier ?</p>
              <div className="menu-popup-buttons">
                <button onClick={confirmRemove}>Confirmer</button>
                <button onClick={cancelRemove}>Annuler</button>
              </div>
            </div>
          </>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Menu;