import React, { useEffect, useState } from 'react';
import { db } from '../../firebase'; 
import { collection, query, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import Satisfaction from './Satisfaction';
import './Confirmation.css';
import Footer from '../Footer';

const Confirmation = () => {
  const [commande, setCommande] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSatisfaction, setShowSatisfaction] = useState(false);
  const location = useLocation();
  const { paymentIntent } = location.state || {}; 
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [tableLabel, setTableLabel] = useState('');
  const navigate = useNavigate();
  const { articles: stateArticles, total: stateTotal } = location.state || {};
  const [articles] = useState(stateArticles || JSON.parse(localStorage.getItem('articles')) || []);
  const [total] = useState(stateTotal || JSON.parse(localStorage.getItem('total')) || 0);


    useEffect(() => {
      if (userInfo.table === 'a_emporter') {
        setTableLabel('À Emporter');
      } else {
        setTableLabel(`Numéro de Table : ${userInfo.table}`);
      }
    }, [userInfo.table]);  

  useEffect(() => {
    if (showSatisfaction) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup function
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showSatisfaction]);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  
    if (!userInfo || !userInfo.id) {
      setError("Utilisateur non trouvé.");
      return;
    }
  
    const fetchCommande = async () => {
      try {
        const commandesRef = collection(db, `clients/${userInfo.id}/commande`);
        const q = query(commandesRef);
  
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            setCommande(doc.data());
          });
        } else {
          setError("Commande non trouvée.");
        }
      } catch (err) {
        setError("Erreur lors de la récupération de la commande.");
        console.error("Erreur:", err);
      }
    };
  
    fetchCommande();
  
    const timer = setTimeout(() => {
      setShowSatisfaction(true);
    }, 3000);
  
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (showSatisfaction) {
      const scrollY = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }

    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
    };
  }, [showSatisfaction]);

    const handleSendInvoice = async () => {
      setLoading(true); // Indique qu'un envoi est en cours
      try {
        // Appel à l'API pour envoyer la facture
        const response = await fetch('https://skaneat-app.vercel.app/send-invoice', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: userInfo.email, // Adresse e-mail du client
            prenom: userInfo.prenom, // Prénom du client
            table: userInfo.table, // Table ou à emporter
            id_client: userInfo.idclient, // ID client
            items: articles, // Liste complète des articles de la commande
            totalCommande: total, // Total global de la commande
          }),
        });
    
        console.log('Données envoyées :', {
          email: userInfo.email,
          prenom: userInfo.prenom,
          table: userInfo.table,
          id_client: userInfo.idclient,
          items: articles,
          totalCommande: total,
        });
    
        const data = await response.json(); // Réponse de l'API
        if (response.ok) {
          alert(data.message); // Notification si l'envoi réussit
        } else {
          console.error('Erreur serveur :', data.error);
          alert('Une erreur est survenue lors de l\'envoi de la facture.');
        }
      } catch (err) {
        console.error('Erreur lors de l\'envoi de la facture :', err);
        alert('Une erreur est survenue lors de l\'envoi de la facture.');
      }
      setLoading(false); // Fin de l'indicateur de chargement
    };    

    const handleFinishOrder = async () => {
      setTimeout(async () => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo) {
          try {
            const clientRef = doc(db, `clients/${userInfo.id}`);
            
            // Supprimer toutes les collections associées au client
            const collectionsToDelete = ['commande']; // Liste des sous-collections
          
            for (const collectionName of collectionsToDelete) {
              const collectionRef = collection(db, `clients/${userInfo.id}/${collectionName}`);
              const snapshot = await getDocs(collectionRef);
              
              const deletePromises = snapshot.docs.map((doc) => deleteDoc(doc.ref));
              await Promise.all(deletePromises);
            }
    
            // Supprimer le document principal du client après avoir supprimé ses sous-collections
            await deleteDoc(clientRef);
            console.log('Client et toutes ses sous-collections supprimés.');
          } catch (error) {
            console.error('Erreur lors de la suppression complète du client:', error);
          }
        }
    
        // Nettoyage du localStorage et redirection
        localStorage.removeItem('userInfo');
        localStorage.removeItem('commande');
        localStorage.removeItem('articles');
        localStorage.removeItem('cart');
        localStorage.removeItem('total');
        navigate('/?success=order_completed');
      }, 500);
    };    

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="confirmation-page">
      {paymentIntent && paymentIntent.status === 'succeeded' ? (
        <>
          <h1>Paiement réussi !</h1>
          <p>Votre paiement de {total.toFixed(2)} € a été effectué avec succès.</p>
          <h2>Résumé de votre commande</h2>
          {commande ? (
            <div className="commande-details">
              <h3>Client: {userInfo.prenom}</h3>
              <h3>{tableLabel}</h3>
              <h3>Numéro de commande : {userInfo.idclient}</h3>
              <div className="command-details">
                {articles.map((item, index) => (
                  <div key={index} className="item">
                    <p><strong>{item.nom}</strong> - Quantité: {item.quantity}</p>
                    <p>Prix unitaire: {item.prix.toFixed(2)} €</p>
                    <p>Prix total: {(item.prix * item.quantity).toFixed(2)} €</p>
                  </div>
                ))
                }
              </div>
              <h3>Total : {total.toFixed(2)} €</h3>

              <button className='send-ticket' onClick={handleSendInvoice} disabled={loading}>
                {loading ? 'Envoi en cours...' : 'Envoyer la facture par e-mail'}
              </button>
              <button className="quit-btn" onClick={handleFinishOrder}>
                Quitter
              </button>
            </div>
          ) : (
            <p>Chargement de votre commande...</p>
          )}
        </>
      ) : (
        <h1>Une erreur est survenue, veuillez réessayer.</h1>
      )}

      {showSatisfaction && (
        <>
          <div className="satisfaction-overlay" />
          <div className="satisfaction-popup">
            <div className="satisfaction-popup-content">
              <Satisfaction setShowSatisfaction={setShowSatisfaction} />
              {/* <button className='close-satisfaction' onClick={() => setShowSatisfaction(false)}>Fermer</button> */}
            </div>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
};

export default Confirmation;
