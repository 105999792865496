import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { doc, setDoc, deleteField, onSnapshot, updateDoc } from 'firebase/firestore';
import './ManageTables.css';

const ManageTables = () => {
  const [nbTables, setNbTables] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');

  // Écoute des modifications en temps réel sur "nb_table" dans Firestore
  useEffect(() => {
    const docRef = doc(db, 'settings', 'nb_table');
    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          setNbTables(docSnap.data().value || null);
        } else {
          setNbTables(null);
        }
        setLoading(false);
      },
      (error) => {
        console.error('Erreur lors de l\'écoute des modifications :', error);
        setLoading(false);
      }
    );

    return () => unsubscribe(); // Nettoyage de l'écouteur lors du démontage
  }, []);

  // Ajouter ou modifier la valeur de nb_table
  const handleSave = async () => {
    if (!inputValue) {
      alert('Veuillez entrer un nombre.');
      return;
    }

    try {
      const docRef = doc(db, 'settings', 'nb_table');
      await setDoc(docRef, { value: parseInt(inputValue, 10) });
      setInputValue('');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de nb_table :', error);
    }
  };

  // Supprimer la valeur de nb_table
  const handleDelete = async () => {
    try {
      const docRef = doc(db, 'settings', 'nb_table');
      await updateDoc(docRef, { value: deleteField() });
    } catch (error) {
      console.error('Erreur lors de la suppression de nb_table :', error);
    }
  };

  if (loading) return <div>
    <div className="dot-spinner">
      <div className="dot-spinner__dot"></div>
      <div className="dot-spinner__dot"></div>
      <div className="dot-spinner__dot"></div>
      <div className="dot-spinner__dot"></div>
      <div className="dot-spinner__dot"></div>
      <div className="dot-spinner__dot"></div>
      <div className="dot-spinner__dot"></div>
      <div className="dot-spinner__dot"></div>
    </div>
  </div>;

  return (
    <div className="manage-tables-container">
      <h2>Gestion du nombre de tables</h2>
      <p>Nombre actuel de tables : <strong>{nbTables !== null ? nbTables : 'Non défini'}</strong></p>

      <div className="manage-tables-actions">
        <input
          type="number"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Entrez un nombre"
          className="manage-tables-input"
        />
        <button onClick={handleSave} className="manage-tables-button save">
          Enregistrer
        </button>
      </div>

      <div className="manage-tables-delete">
        <button onClick={handleDelete} className="manage-tables-button delete">
          Supprimer la valeur
        </button>
      </div>
    </div>
  );
};

export default ManageTables;
