import React, { useEffect, useState, useCallback } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./AdminHome.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AdminHome = () => {
  const [revenueData, setRevenueData] = useState({
    dailyRevenue: {},
    weeklyRevenue: {},
    monthlyRevenue: {},
  });
  const [showComparison, setShowComparison] = useState({
    daily: false,
    weekly: false,
    monthly: false,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const normalizeDate = (date) => {
    if (date.seconds) {
      // Firestore Timestamp object
      return new Date(date.seconds * 1000);
    }
    return new Date(date); // Assume regular Date object
  };

  const calculateLast7DaysRevenue = useCallback((commandes, offset = 0) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    today.setDate(today.getDate() - offset);
  
    const start = new Date(today);
    start.setDate(today.getDate() - 6);
  
    const revenues = Array(7).fill(0);
    const labels = Array(7)
      .fill(0)
      .map((_, i) => {
        const date = new Date(start);
        date.setDate(start.getDate() + i);
        return date.toLocaleDateString("fr-FR", { weekday: "short", day: "numeric" });
      });
  
    commandes.forEach((commande) => {
      const orderDate = normalizeDate(commande.date);
      orderDate.setHours(0, 0, 0, 0);
      if (orderDate >= start && orderDate <= today) {
        const diffInDays = Math.round((orderDate - start) / (1000 * 60 * 60 * 24));
        if (diffInDays >= 0 && diffInDays < 7) {
          revenues[diffInDays] += commande.total;
        }
      }
    });
  
    const todayRevenue = revenues[6]; // Dernier jour
    const yesterdayRevenue = revenues[5]; // Avant-dernier jour
    const percentageChange = calculatePercentageChange(todayRevenue, yesterdayRevenue);
  
    return { revenues, labels, percentageChange };
  }, []);  

  const calculateLast4WeeksRevenue = useCallback((commandes, offset = 0) => {
    const today = new Date();
    today.setDate(today.getDate() - offset * 7);
  
    const revenues = Array(4).fill(0);
    const labels = Array(4)
      .fill(0)
      .map((_, i) => {
        const date = new Date(today);
        date.setDate(today.getDate() - i * 7);
        return `Semaine ${getWeekNumber(date)}`;
      })
      .reverse();
  
    commandes.forEach((commande) => {
      const orderDate = normalizeDate(commande.date);
      const weekNumberCommande = getWeekNumber(orderDate);
      const yearCommande = orderDate.getFullYear();
      const diffInWeeks = getWeekNumber(today) - weekNumberCommande;
  
      if (yearCommande === 2024 && weekNumberCommande === 53) {
        revenues[3] += commande.total;
      } else if (diffInWeeks >= 0 && diffInWeeks < 4) {
        revenues[3 - diffInWeeks] += commande.total;
      }
    });
  
    const currentWeekRevenue = revenues[3];
    const previousWeekRevenue = revenues[2];
    const percentageChange = calculatePercentageChange(currentWeekRevenue, previousWeekRevenue);
  
    return { revenues, labels, percentageChange };
  }, []);  

  const calculateLast12MonthsRevenue = useCallback((commandes, offset = 0) => {
    const today = new Date();
    const revenues = Array(12).fill(0);
    const labels = Array(12)
      .fill(0)
      .map((_, i) => {
        const date = new Date(today);
        date.setMonth(today.getMonth() - i - offset);
        return date.toLocaleDateString("fr-FR", { month: "short" });
      })
      .reverse();
  
    commandes.forEach((commande) => {
      const orderDate = normalizeDate(commande.date);
      const diffInMonths = today.getMonth() - orderDate.getMonth() + 12 * (today.getFullYear() - orderDate.getFullYear());
      if (diffInMonths >= offset && diffInMonths < offset + 12) {
        revenues[11 - (diffInMonths - offset)] += commande.total;
      }
    });
  
    const currentMonthRevenue = revenues[11];
    const previousMonthRevenue = revenues[10];
    const percentageChange = calculatePercentageChange(currentMonthRevenue, previousMonthRevenue);
  
    return { revenues, labels, percentageChange };
  }, []);  

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const dayOfYear = ((date - firstDayOfYear + 86400000) / 86400000);
    const weekNumber = Math.ceil((dayOfYear + firstDayOfYear.getDay()) / 7);
  
    // Gérer les années avec 53 semaines
    const is53WeekYear = (year) => {
      const lastDayOfYear = new Date(year, 11, 31);
      return lastDayOfYear.getDay() === 4 || new Date(year, 11, 30).getDay() === 4;
    };
  
    if (weekNumber === 53 && !is53WeekYear(date.getFullYear())) {
      return 1; // Revenir à la semaine 1 pour les années suivantes
    }
  
    return weekNumber;
  };  

  useEffect(() => {
    const fetchRevenueData = async () => {
      const commandesSnapshot = await getDocs(collection(db, "total_commande"));
      const commandes = commandesSnapshot.docs.map((doc) => doc.data());

      const dailyRevenue = calculateLast7DaysRevenue(commandes);
      const dailyComparison = calculateLast7DaysRevenue(commandes, 7);
      const weeklyRevenue = calculateLast4WeeksRevenue(commandes);
      const weeklyComparison = calculateLast4WeeksRevenue(commandes, 4);
      const monthlyRevenue = calculateLast12MonthsRevenue(commandes);
      const monthlyComparison = calculateLast12MonthsRevenue(commandes, 12);

      setRevenueData({
        dailyRevenue: { current: dailyRevenue, comparison: dailyComparison },
        weeklyRevenue: { current: weeklyRevenue, comparison: weeklyComparison },
        monthlyRevenue: { current: monthlyRevenue, comparison: monthlyComparison },
      });
    };

    fetchRevenueData();
  }, [calculateLast7DaysRevenue, calculateLast4WeeksRevenue, calculateLast12MonthsRevenue]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return current === 0 ? 0 : 100; // Si le précédent est 0, retournez 100% ou 0%
    return ((current - previous) / previous) * 100;
  };  

  return (
    <div className="admin-home">
      <h1>Page d'Accueil</h1>
      <div className="charts">
        {["daily", "weekly", "monthly"].map((period) => (
          <div className="chart-container" key={period}>
            <h2 style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              Revenus {period === "daily" ? "quotidiens" : period === "weekly" ? "hebdomadaires" : "mensuels"}
              {revenueData[`${period}Revenue`]?.current?.percentageChange !== undefined && (
                <span
                  style={{
                    color: revenueData[`${period}Revenue`].current.percentageChange >= 0 ? "green" : "red",
                    fontWeight: "bold",
                  }}
                >
                  {revenueData[`${period}Revenue`].current.percentageChange.toFixed(1)}%
                </span>
              )}
            </h2>
            {!isMobile && (
              <button
                onClick={() => setShowComparison((prev) => ({ ...prev, [period]: !prev[period] }))}
              >
                {showComparison[period] ? "Masquer Comparaison" : "Comparer"}
              </button>
            )}
            {revenueData[`${period}Revenue`]?.current ? (
              isMobile ? (
                <Bar
                  data={{
                    labels: [revenueData[`${period}Revenue`].current.labels.slice(-1)[0]],
                    datasets: [{
                      label: `Revenus ${period}`,
                      data: [revenueData[`${period}Revenue`].current.revenues.slice(-1)[0]],
                      backgroundColor: "rgba(75, 192, 192, 0.2)",
                      borderColor: "rgba(75, 192, 192, 1)",
                      borderWidth: 1,
                    }],
                  }}
                  options={{
                    scales: {
                      y: {
                        beginAtZero: true
                      }
                    }
                  }}
                />
              ) : (
                <Line
                  data={{
                    labels: revenueData[`${period}Revenue`].current.labels,
                    datasets: [
                      {
                        label: `Revenus ${period === "daily" ? "quotidiens" : period === "weekly" ? "hebdomadaires" : "mensuels"}`,
                        data: revenueData[`${period}Revenue`].current.revenues,
                        borderColor: "rgba(75, 192, 192, 1)",
                        backgroundColor: "rgba(75, 192, 192, 0.2)",
                        fill: true,
                        tension: 0.1,
                      },
                      ...(showComparison[period]
                        ? [
                            {
                              label: `Revenus (${period === "daily" ? "Semaine dernière" : period === "weekly" ? "4 Semaines précédentes" : "12 Mois précédents"})`,
                              data: revenueData[`${period}Revenue`].comparison.revenues,
                              borderColor: "rgba(255, 99, 132, 1)",
                              backgroundColor: "rgba(255, 99, 132, 0.2)",
                              fill: true,
                              tension: 0.1,
                            },
                          ]
                        : []),
                    ],
                  }}
                />
              )
            ) : (
              <p>Chargement des données...</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminHome;
