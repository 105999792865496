import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, doc, deleteDoc, updateDoc, getDoc, onSnapshot } from 'firebase/firestore';
import axios from 'axios';
import './view.css';

const sortSupplementCategories = (supplements) => {
  if (!supplements) return {};
  return Object.entries(supplements)
    .sort(([a], [b]) => a.localeCompare(b))
    .reduce((acc, [key, value]) => {
      acc[key] = {
        ...value,
        items: value.items.sort((a, b) => a.localeCompare(b))
      };
      return acc;
    }, {});
};

const ViewMenuItems = ({ menuId }) => {
  const [categorie, setCategorie] = useState('');
  const [items, setItems] = useState([]);
  const [error] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [editingField, setEditingField] = useState(null);
  const [editingSupplements, setEditingSupplements] = useState(null);
  const [availableSupplements, setAvailableSupplements] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  const handleDelete = async (item) => {
    try {
      await deleteDoc(doc(db, `menu/${menuId}/${categorie}`, item.id));
      setConfirmation('Élément supprimé avec succès !');
      triggerPopup();
      fetchItems(categorie);
    } catch (error) {
      console.error('Erreur lors de la suppression:', error);
    }
  };

  const handleToggleActive = async (item) => {
    try {
      const updatedState = !item.actif;
      const itemRef = doc(db, `menu/${menuId}/${categorie}`, item.id);
      await updateDoc(itemRef, { actif: updatedState });
      setConfirmation(`Élément ${updatedState ? 'activé' : 'désactivé'} avec succès !`);
      triggerPopup();
      fetchItems(categorie);
    } catch (error) {
      console.error('Erreur lors du changement d\'état:', error);
    }
  };

  const handleFieldUpdate = async (item, field, value) => {
    try {
      const itemRef = doc(db, `menu/${menuId}/${categorie}`, item.id);
      await updateDoc(itemRef, { [field]: value });
      setEditingField(null);
      setConfirmation('Champ mis à jour avec succès !');
      triggerPopup();
      fetchItems(categorie);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du champ:', error);
    }
  };

  const triggerPopup = () => {
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 2000);
  };

  const handlePhotoChange = async (item, photoFile) => {
    try {
      const formData = new FormData();
      formData.append('file', photoFile);
      formData.append('upload_preset', 'menu_photos');
      formData.append('cloud_name', 'dyuyrwww1');

      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/dyuyrwww1/image/upload`,
        formData
      );

      const photoURL = response.data.secure_url;
      await handleFieldUpdate(item, 'photo', photoURL);
    } catch (error) {
      console.error('Erreur lors du téléchargement de la photo:', error);
    }
  };

  const fetchSupplements = useCallback(async () => {
    try {
      const supplementRef = doc(db, 'menu', menuId);
      const docSnapshot = await getDoc(supplementRef);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setAvailableSupplements(sortSupplementCategories(data.suppléments || {}));
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des suppléments:', error);
      setAvailableSupplements({});
    }
  }, [menuId]);

  const fetchItems = useCallback(async (cat) => {
    try {
      const itemsCollection = collection(db, `menu/${menuId}/${cat}`);
      const unsubscribe = onSnapshot(itemsCollection, (itemSnapshot) => {
        const itemList = itemSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setItems(itemList);
        setFilteredItems(itemList);
      });
  
      return () => unsubscribe();
    } catch (error) {
      console.error('Erreur lors de la récupération des éléments:', error);
    }
  }, [menuId]);  

  const handleCategoryChange = useCallback((cat) => {
    setCategorie(cat);
    fetchItems(cat);
  }, [fetchItems]);

  useEffect(() => {
    handleCategoryChange('entrees');
    fetchSupplements();
  }, [handleCategoryChange, fetchSupplements]);  

  const fetchAllItems = useCallback(async () => {
    try {
      const menuCategories = ['entrees', 'plats', 'desserts', 'boissons'];
      let allItems = [];
      
      for (const cat of menuCategories) {
        const itemsCollection = collection(db, `menu/${menuId}/${cat}`);
        const itemSnapshot = await getDocs(itemsCollection);
        const itemList = itemSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          category: cat
        }));
        allItems = [...allItems, ...itemList];
      }
      
      return allItems;
    } catch (error) {
      console.error('Erreur lors de la récupération des éléments:', error);
      return [];
    }
  }, [menuId]);

  useEffect(() => {
    if (searchTerm) {
      fetchAllItems().then(allItems => {
        const filtered = allItems.filter(item => 
          item.nom.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredItems(filtered);
      });
    } else {
      setFilteredItems(items);
    }
  }, [searchTerm, items, fetchAllItems]);

  useEffect(() => {
    handleCategoryChange('entrees');
    fetchSupplements();
  }, [handleCategoryChange, fetchSupplements]);

  const initializeEditingSupplements = (item, supplements) => {
    const initialState = {
      itemId: item.id
    };
  
    // Initialize each category with empty arrays and the new fields
    Object.keys(availableSupplements).forEach(category => {
      const categoryData = availableSupplements[category];
      initialState[category] = {
        items: (supplements && supplements[category]?.items) || [],
        selectionType: categoryData.selectionType,
        maxElement: categoryData.maxElement || 1,  // Par défaut 1 si non défini
        isRequired: categoryData.isRequired || false // Par défaut false si non défini
      };
    });
  
    return initialState;
  };

  const handleUpdateSupplements = async (item) => {
    try {
      // Crée une version propre des suppléments sans l'itemId
      const cleanSupplements = Object.entries(editingSupplements)
        .filter(([key]) => key !== 'itemId')
        .reduce((acc, [category, data]) => {
          if (data.items && data.items.length > 0) {
            acc[category] = {
              items: data.items,
              selectionType: data.selectionType,
              maxElement: data.maxElement,  // Ajoute maxElement
              isRequired: data.isRequired   // Ajoute isRequired
            };
          }
          return acc;
        }, {});
  
      const itemRef = doc(db, `menu/${menuId}/${categorie}`, item.id);
      await updateDoc(itemRef, {
        suppléments: cleanSupplements
      });
      
      setEditingSupplements(null);
      setConfirmation('Suppléments mis à jour avec succès !');
      triggerPopup();
      fetchItems(categorie);
    } catch (error) {
      console.error('Erreur lors de la mise à jour des suppléments:', error);
    }
  };  

  return (
    <div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Rechercher un plat..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>

      <div className="view-menu-items-buttons">
        <button onClick={() => handleCategoryChange('entrees')}>Entrées</button>
        <button onClick={() => handleCategoryChange('plats')}>Plats</button>
        <button onClick={() => handleCategoryChange('desserts')}>Desserts</button>
        <button onClick={() => handleCategoryChange('boissons')}>Boissons</button>
      </div>

      {error && <p className="error-message">{error}</p>}

      {searchTerm && filteredItems.length === 0 && (
        <div className="no-results">
          Aucun résultat trouvé pour "{searchTerm}"
        </div>
      )}

      {!searchTerm && <h3>{categorie.charAt(0).toUpperCase() + categorie.slice(1)}</h3>}

      <ul className='view-menu-items-list'>
        {(searchTerm ? filteredItems : items).map((item) => (
          <li className='cart-view' key={item.id}>
            {searchTerm && (
              <div className="item-category">
                {item.category === 'entrees' ? 'Entrées' :
                 item.category === 'plats' ? 'Plats' :
                 item.category === 'desserts' ? 'Desserts' :
                 'Boissons'}
              </div>
            )}
            <br/>
            <span className='title-classname-product'
              onClick={() => setEditingField(`nom-${item.id}`)}
              style={{ cursor: 'pointer', textDecoration: editingField === `nom-${item.id}` ? 'underline' : 'none' }}
            >
              {editingField === `nom-${item.id}` ? (
                <input
                  type="text"
                  defaultValue={item.nom}
                  onBlur={(e) => handleFieldUpdate(item, 'nom', e.target.value)}
                  autoFocus
                />
              ) : (
                <strong>{item.nom}</strong>
              )}
            </span>
            <br />
            <span className='menu-item-price'
              onClick={() => setEditingField(`prix-${item.id}`)}
              style={{ cursor: 'pointer', textDecoration: editingField === `prix-${item.id}` ? 'underline' : 'none' }}
            >
              {editingField === `prix-${item.id}` ? (
                <input
                  type="number"
                  defaultValue={item.prix.toFixed(2)}
                  onBlur={(e) => handleFieldUpdate(item, 'prix', parseFloat(e.target.value))}
                  autoFocus
                />
              ) : (
                `${item.prix.toFixed(2)} €`
              )}
            </span>
            <br />
            <span
              onClick={() => setEditingField(`description-${item.id}`)}
              style={{ cursor: 'pointer', textDecoration: editingField === `description-${item.id}` ? 'underline' : 'none' }}
            >
              {editingField === `description-${item.id}` ? (
                <textarea
                  defaultValue={item.description}
                  onBlur={(e) => handleFieldUpdate(item, 'description', e.target.value)}
                  autoFocus
                />
              ) : (
                item.description
              )}
            </span>
            <br />
            {item.photo ? (
              <div className="image-container">
                <img
                  src={item.photo}
                  alt={item.nom}
                  className="menu-item-photo"
                  onClick={() => document.getElementById(`fileInput-${item.id}`).click()}
                />
              </div>
            ) : (
              <button
                onClick={() => document.getElementById(`fileInput-${item.id}`).click()}
                className='add-photo-button'
              >
                Ajouter une photo
              </button>
            )}
            <input
              type="file"
              id={`fileInput-${item.id}`}
              style={{ display: 'none' }}
              onChange={(e) => handlePhotoChange(item, e.target.files[0])}
            />
            <br />
            <span>Votre article est {item.actif ? 'actif' : 'inactif'}</span> <br />

            <div className="supplements-popup">
              {item.suppléments && Object.keys(item.suppléments).length > 0 ? (
                <>
                  <h4 className='show-availible-supp'>Suppléments disponibles :</h4>

                  {Object.entries(sortSupplementCategories(item.suppléments)).map(([category, data]) => (
                    <div key={category} className="supplement-category-popup">
                      <div className='catrgory-title-supp'>{category} :</div>
                      <div className='catrgory-name-supp'>
                        {data.items.map((supplement, index) => (
                          <span key={`${category}-${supplement}-${index}`}>
                            {supplement}{index < data.items.length - 1 && ', '}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                  
                  <button
                    className="edit-supplements-button"
                    onClick={() => setEditingSupplements(
                      initializeEditingSupplements(item, item.suppléments)
                    )}
                  >
                    Modifier les suppléments
                  </button>
                </>
              ) : (
                <button
                  className="add-supplements-button"
                  onClick={() => setEditingSupplements(
                    initializeEditingSupplements(item)
                  )}
                >
                  Ajouter des suppléments
                </button>
              )}
            </div>

            <button className='view-supp-button' onClick={() => handleDelete(item)}>Supprimer</button>
            <button onClick={() => handleToggleActive(item)}>
              {item.actif ? 'Désactiver' : 'Activer'}
            </button>
          </li>
        ))}
      </ul>

      {editingSupplements && (
        <div className="popup-overlay-supplements" onClick={() => setEditingSupplements(null)}>
          <div className="popup-content-supplements" onClick={e => e.stopPropagation()}>
            <div className="popup-header-supplements">
              <h3>Modifier les suppléments</h3>
              <button 
                className="close-button-supplements"
                onClick={() => setEditingSupplements(null)}
              >
                ×
              </button>
            </div>
            <div className="popup-body-supplements">
              {Object.entries(availableSupplements).map(([category, categoryData]) => (
                <div key={category} className="supplement-category-body">
                  <div className='category-name-popup'>{category} :</div>
                  <div className="supplement-items-popup">
                    {categoryData.items.map((supplement) => (
                      <label key={supplement} className="checkbox-label-supplements">
                        <input
                          type="checkbox"
                          checked={editingSupplements[category]?.items?.includes(supplement) || false}
                          onChange={() => {
                            const currentItems = editingSupplements[category]?.items || [];
                            const newItems = currentItems.includes(supplement)
                              ? currentItems.filter(item => item !== supplement)
                              : [...currentItems, supplement];
                            
                            setEditingSupplements(prev => ({
                              ...prev,
                              [category]: {
                                items: newItems,
                                selectionType: categoryData.selectionType,
                                maxElement: prev[category]?.maxElement || categoryData.maxElement,
                                isRequired: prev[category]?.isRequired || categoryData.isRequired
                              }
                            }));
                          }}
                        />
                        <svg viewBox="0 0 64 64" height="2em" width="2em">
                          <path
                            d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                            pathLength="575.0541381835938"
                            className="path"
                          ></path>
                        </svg>
                        <span>{supplement}</span>
                      </label>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="popup-footer-supplements">
              <button 
                className="validate-button-popup"
                onClick={() => handleUpdateSupplements(items.find(item => item.id === editingSupplements.itemId))}
              >
                Valider
              </button>
              <button 
                className="cancel-button-popup"
                onClick={() => setEditingSupplements(null)}
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}

      {showPopup && (
        <div className={`popup-confirmation ${showPopup ? 'fade-in' : 'fade-out'}`}>
          {confirmation}
        </div>
      )}
    </div>
  );
};

export default ViewMenuItems;