import React, { useState } from 'react';
import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import './Satisfaction.css';

const Satisfaction = ({ setShowSatisfaction }) => {
  const [ratings, setRatings] = useState({
    plats: 0,
    service: 0,
    application: 0,
  });
  const [feedback, setFeedback] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleRatingChange = (category, value) => {
    setRatings({ ...ratings, [category]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (ratings.plats === 0 || ratings.service === 0 || ratings.application === 0) {
      setErrorMessage('Veuillez attribuer une note pour chaque catégorie.');
      return;
    }
  
    try {
      await addDoc(collection(db, 'satisfaction'), {
        ...ratings,
        feedback,
        date: new Date(),
      });
      setSuccessMessage(true);
      setErrorMessage('');
      setTimeout(() => {
        setSuccessMessage(false);
        setShowSatisfaction(false);
      }, 500);
    } catch (error) {
      console.error('Erreur lors de l\'ajout des données de satisfaction:', error);
    }
  };

  return (
    <>
      <h2 className="satisfaction-title">Merci d'avoir commandé chez "Notre Resto"</h2>
      <p className="satisfaction-subtitle">Nous aimerions connaître votre avis pour améliorer notre service.</p>
      
      <form onSubmit={handleSubmit} className="satisfaction-form">
        <div className="ratings-container">
          {[
            { key: 'plats', label: 'Plats' },
            { key: 'service', label: 'Service' },
            { key: 'application', label: 'Application' }
          ].map(({ key, label }) => (
            <div key={key} className="rating-group">
              <label>{label}</label>
              <div className="stars">
                {[1, 2, 3, 4, 5].map((value) => (
                  <span
                    key={value}
                    className={`star ${ratings[key] >= value ? 'selected' : ''}`}
                    onClick={() => handleRatingChange(key, value)}
                  >
                    ★
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="feedback-group">
          <label>Commentaires</label>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Partagez vos suggestions ou remarques ici..."
          />
        </div>

        <div className="satisfaction-buttons">
          <button type="submit" className="satisfaction-submit-btn">
            Envoyer
          </button>
          <button 
            type="button" 
            className="satisfaction-close-btn"
            onClick={() => setShowSatisfaction(false)}
          >
            Fermer
          </button>
        </div>
      </form>

      {errorMessage && <p className="satisfaction-error-message">{errorMessage}</p>}
      {successMessage && <p className="satisfaction-success-message">Merci pour vos retours !</p>}
    </>
  );
};

export default Satisfaction;