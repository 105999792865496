import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, query, where, onSnapshot, updateDoc, doc, setDoc, deleteDoc, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { getDoc } from 'firebase/firestore';
import { auth } from '../../firebase';
import './ServerOrder.css';

const menuId = "ZifkqWN99oRgRNM0Nisz";

const ServerOrder = () => {
  const [initialisedCommandes, setInitialisedCommandes] = useState([]);
  const [acceptedCommandes, setAcceptedCommandes] = useState([]);
  const [completedCommandes, setCompletedCommandes] = useState([]);
  const [user, setUser] = useState(null);
  const [serverFirstName, setServerFirstName] = useState('');
  const [searchId, setSearchId] = useState('');
  const navigate = useNavigate();
  const [editingOrder, setEditingOrder] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingItems, setEditingItems] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedComment, setSelectedComment] = React.useState(null);
  const cleanItemName = (name) => name.replace(/\s*\(.*?\)\s*/g, '').trim();

  useEffect(() => {
    const fetchServerInfo = async () => {
      if (user) {
        try {
          const serverRef = doc(db, 'identifications', user.uid);
          const serverSnap = await getDoc(serverRef);
  
          if (serverSnap.exists()) {
            setServerFirstName(serverSnap.data().firstName);
          } else {
            console.error("Aucune donnée pour ce serveur.");
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des informations du serveur :", error);
        }
      }
    };
  
    fetchServerInfo();
  }, [user]);
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchCommandes = async () => {
      try {
        const clientsCollection = collection(db, 'clients');

        onSnapshot(clientsCollection, (clientsSnapshot) => {
            clientsSnapshot.forEach(async (clientDoc) => {
              const clientId = clientDoc.id;
              const clientData = clientDoc.data();
              const commandesCollection = collection(db, `clients/${clientId}/commande`);
          
              // Query pour récupérer les commandes "Initialisé"
              const qInitialised = query(commandesCollection, where('statut', '==', 'Initialisée'));
              // Query pour récupérer les commandes "Accepté"
              const qAccepted = query(commandesCollection, where('statut', '==', 'Accepté'));
              // Query pour récupérer les commandes "Terminé"
              const qCompleted = query(commandesCollection, where('statut', '==', 'Terminé'));
          
              // Écoute en temps réel des commandes "Initialisé"
              onSnapshot(qInitialised, (commandesSnapshot) => {
                let clientInitialisedOrders = [];
          
                commandesSnapshot.forEach((commandeDoc) => {
                  const commandeData = commandeDoc.data();
                  const elapsedTime = Math.floor((Date.now() - commandeData.date.toDate()) / 60000); // Calculer le temps écoulé en minutes
          
                  clientInitialisedOrders.push({
                    clientId,
                    commandeId: commandeDoc.id,
                    table: clientData.table, // Ajouter le numéro de table du client
                    idclient: clientData.idclient,
                    prenom: clientData.prenom, // Ajouter le prénom du client
                    email: clientData.email, // Ajouter l'email du client
                    elapsedTime,
                    ...commandeData,
                  });
                });
          
                setInitialisedCommandes((prevCommandes) => [
                  ...prevCommandes.filter((cmd) => cmd.clientId !== clientId),
                  ...clientInitialisedOrders,
                ]);
              });
          
              // Écoute en temps réel des commandes "Accepté"
              onSnapshot(qAccepted, (commandesSnapshot) => {
                let clientAcceptedOrders = [];
          
                commandesSnapshot.forEach((commandeDoc) => {
                  const commandeData = commandeDoc.data();
                  const elapsedTime = Math.floor((Date.now() - commandeData.date.toDate()) / 60000); // Calculer le temps écoulé en minutes
          
                  clientAcceptedOrders.push({
                    clientId,
                    commandeId: commandeDoc.id,
                    table: clientData.table, // Ajouter le numéro de table du client
                    idclient: clientData.idclient,
                    prenom: clientData.prenom, // Ajouter le prénom du client
                    email: clientData.email, // Ajouter l'email du client
                    elapsedTime,
                    ...commandeData,
                  });
                });
          
                setAcceptedCommandes((prevCommandes) => [
                  ...prevCommandes.filter((cmd) => cmd.clientId !== clientId),
                  ...clientAcceptedOrders,
                ]);
              });
          
              // Écoute en temps réel des commandes "Terminé"
              onSnapshot(qCompleted, (commandesSnapshot) => {
                let clientCompletedOrders = [];
          
                commandesSnapshot.forEach((commandeDoc) => {
                  clientCompletedOrders.push({
                    clientId,
                    commandeId: commandeDoc.id,
                    table: clientData.table, // Ajouter le numéro de table du client
                    idclient: clientData.idclient,
                    prenom: clientData.prenom, // Ajouter le prénom du client
                    email: clientData.email, // Ajouter l'email du client
                    ...commandeDoc.data(),
                  });
                });
          
                setCompletedCommandes((prevCommandes) => [
                  ...prevCommandes.filter((cmd) => cmd.clientId !== clientId),
                  ...clientCompletedOrders,
                ]);
              });
            });
          });          
      } catch (error) {
        console.error("Erreur lors de la récupération des commandes: ", error);
      }
    };

    fetchCommandes();
  }, []);

  // Gérer l'acceptation de la commande
  const handleAcceptOrder = async (clientId, commandeId, clientPrenom, clientEmail) => {
    try {
      const commandeRef = doc(db, `clients/${clientId}/commande`, commandeId);
      const now = new Date(); // Obtenir l'heure actuelle
  
      await updateDoc(commandeRef, {
        statut: 'Accepté',
        date: now, // Mettre à jour la date de l'acceptation
        serverName: serverFirstName, // Ajouter le nom du serveur qui accepte
      });
  
      // Mettre à jour l'état local après avoir accepté une commande
      setInitialisedCommandes((prevCommandes) =>
        prevCommandes.filter((commande) => commande.commandeId !== commandeId)
      );
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la commande: ", error);
    }
  };

  // Gérer la finalisation de la commande et l'envoi dans "total_commande"
  const handleCompleteOrder = async (clientId, commandeId, commandeItems, clientPrenom, clientIdClient) => {
    try {
      const commandeRef = doc(db, `clients/${clientId}/commande`, commandeId);
      const now = new Date(); // Obtenir l'heure actuelle
      await updateDoc(commandeRef, { statut: 'Terminé', date: now }); // Mettre à jour le statut et la date
  
      // Calculer le total de la commande
      const totalAmount = commandeItems.reduce((acc, item) => acc + item.prix * item.quantity, 0);

      const cleanedItems = commandeItems.map(item => ({
        ...item,
        nom: cleanItemName(item.nom) // Nettoie le nom de l'article
      }));
  
      // Ajouter le prénom et idclient dans la collection "total_commande"
      const totalCommandeRef = doc(db, 'total_commande', commandeId);
      await setDoc(totalCommandeRef, {
        commandeId: commandeId,
        items: cleanedItems, // Utilisation des articles nettoyés
        total: totalAmount,
        date: now, // Inclure la date de finalisation de la commande
        prenom: clientPrenom, // Ajouter le prénom du client
        idclient: clientIdClient, // Ajouter l'idclient du client
      });
  
      // Mettre à jour l'état local après avoir terminé une commande
      setAcceptedCommandes((prevCommandes) => prevCommandes.filter(commande => commande.commandeId !== commandeId));
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la commande: ", error);
    }
  };  

  // Gérer le changement de statut de la commande en "Commande servi"
  const handleServeOrder = async (clientId, commandeId) => {
    try {
      const commandeRef = doc(db, `clients/${clientId}/commande`, commandeId);
      const now = new Date(); // Obtenir l'heure actuelle
      await updateDoc(commandeRef, { statut: 'Commande servi', date: now }); // Mettre à jour le statut et la date

      // Mettre à jour l'état local après avoir servi une commande
      setCompletedCommandes((prevCommandes) => prevCommandes.filter(commande => commande.commandeId !== commandeId));
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut de la commande: ", error);
    }
  };

  // Fonction pour mettre à jour le timer toutes les minutes
  useEffect(() => {
    const interval = setInterval(() => {
      setInitialisedCommandes((prevCommandes) =>
        prevCommandes.map((commande) => ({
          ...commande,
          elapsedTime: Math.floor((Date.now() - commande.date.toDate()) / 60000), // Mettre à jour le temps écoulé
        }))
      );
      setAcceptedCommandes((prevCommandes) =>
        prevCommandes.map((commande) => ({
          ...commande,
          elapsedTime: Math.floor((Date.now() - commande.date.toDate()) / 60000), // Mettre à jour le temps écoulé
        }))
      );
    }, 60000); // Mettre à jour toutes les minutes

    return () => clearInterval(interval); // Nettoyer l'intervalle à la désinstallation
  }, []);

  const filterCommandes = (commandes) => {
    if (!searchId) return commandes; // Si aucune recherche, afficher toutes les commandes
    return commandes.filter((commande) => commande.idclient?.toString().includes(searchId));
  };

  const handleEditOrder = (commande) => {
    setEditingOrder(commande);
    setEditingItems([...commande.items]); // Créer une copie des items pour l'édition
    setIsEditModalOpen(true);
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = [...editingItems];
    updatedItems[index] = { ...updatedItems[index], quantity: parseInt(newQuantity) || 0 };
    setEditingItems(updatedItems);
  };

  const handleSaveEdit = async () => {
    try {
      // Vérifier si toutes les quantités sont à 0
      const hasItems = editingItems.some(item => item.quantity > 0);
      
      if (!hasItems) {
        // Si toutes les quantités sont à 0, supprimer la commande
        const commandeRef = doc(db, `clients/${editingOrder.clientId}/commande`, editingOrder.commandeId);
        await deleteDoc(commandeRef);
      } else {
        // Sinon, mettre à jour la commande avec les nouvelles quantités
        const commandeRef = doc(db, `clients/${editingOrder.clientId}/commande`, editingOrder.commandeId);
        await updateDoc(commandeRef, {
          items: editingItems.filter(item => item.quantity > 0) // Ne garder que les items avec une quantité > 0
        });
      }
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la commande:", error);
    }
  };

  // Ajouter cette fonction pour charger le menu
  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const categories = ['entrees', 'plats', 'desserts', 'boissons'];
        let allItems = [];

        for (const category of categories) {
          const productsCollection = collection(db, `menu/${menuId}/${category}`);
          const q = query(productsCollection);
          const querySnapshot = await getDocs(q);
          
          querySnapshot.docs.forEach(doc => {
            allItems.push({
              id: doc.id,
              category: category,
              ...doc.data()
            });
          });
        }

        setMenuItems(allItems);
      } catch (error) {
        console.error("Erreur lors de la récupération du menu:", error);
      }
    };

    fetchMenu();
  }, []);

  // Ajouter cette fonction pour gérer l'ajout d'un item
  const handleAddItem = () => {
    if (selectedItem) {
      const newItem = {
        id: selectedItem.id,
        nom: selectedItem.nom,
        prix: selectedItem.prix,
        quantity: 1
      };
      setEditingItems([...editingItems, newItem]);
      setShowAddItemForm(false);
      setSelectedItem(null);
    }
  };

  return (
    <div className="server-interface-container">
      <h1 className="server-interface-title">Interface Serveur</h1>

      <input
        type="tel"
        placeholder="Rechercher par ID client"
        value={searchId}
        onChange={(e) => setSearchId(e.target.value)}
        className="search-bar"
      />

      {user ? (
        <div className='all-commande-statut'>
          {/* Commandes initialisées */}
          <h2 className="commande-section-title">Commandes initialisées</h2>
          {initialisedCommandes.length > 0 ? (
            <div className="commandes-container">
              {filterCommandes(initialisedCommandes).map((commande) => (
                <div key={commande.commandeId} className="commande-card">
                  <h3>
                    {commande.table === 'a_emporter' ? 'À Emporter' : `Table n°${commande.table}`} - {commande.idclient}
                  </h3>
                  <p className='client-name-order'>Client : {commande.prenom}</p>
                  <h4 className='order-state-time'>Durée dans cet état : <br/>{commande.elapsedTime} minute(s)</h4>
                  <h4>Commandes :</h4>
                  <div className='order-box'>
                    {commande.items.map((item, index) => (
                      <p key={index} className='my-order-print'>
                        • <strong>{item.nom}</strong> <br/>
                        Quantité: {item.quantity} <br/>
                        Prix unitaire: {item.prix.toFixed(2)} € <br/>
                      </p>
                    ))}
                  </div>
                  {commande.commentaire && (
                    <div>
                      <button 
                        className="view-comment-button" 
                        onClick={() => setSelectedComment(commande.commentaire)}
                      >
                        Voir le commentaire
                      </button>

                      {/* Popup pour afficher le commentaire */}
                      {selectedComment && (
                        <div className="comment-modal">
                          <div className="comment-modal-content">
                            <h3>Commentaire du client :</h3>
                            <p>{selectedComment}</p>
                            <button className="close-modal-button" onClick={() => setSelectedComment(null)}>Fermer</button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <h4 className='total-order-price'>Prix total: {commande.items.reduce((acc, item) => acc + item.prix * item.quantity, 0).toFixed(2)} €</h4>
                  <button className="accept-action-button accept" onClick={() => handleAcceptOrder(commande.clientId, commande.commandeId, commande.prenom, commande.email)}>Accepter</button>
                </div>
              ))}
            </div>
          ) : (
            <p className='no-traitement'>Aucune commande à traiter.</p>
          )}

          {/* Commandes acceptées */}
          <h2 className="commande-section-title">Commandes acceptées & en préparation</h2>
          {acceptedCommandes.length > 0 ? (
            <div className="commandes-container">
              {filterCommandes(acceptedCommandes).map((commande) => {
                // Vérifier si tous les plats et boissons sont prêts
                const isReadyToServe = commande.items.every(
                  (item) =>
                    item.dish_statut === "prepared_dish" ||
                    item.drink_statut === "prepared_drink"
                );

                return (
                  <div key={commande.commandeId} className="commande-card">
                    {isReadyToServe && (
                      <h3 className="ready-to-serve-title">Commande prête à être servie</h3>
                    )}
                    <h3>
                      {commande.table === 'a_emporter' ? 'À Emporter' : `Table n°${commande.table}`} - {commande.idclient}
                    </h3>
                    <p className='client-name-order'>Client : {commande.prenom}</p>
                    <h4 className='order-state-time'>Durée dans cet état : <br/>{commande.elapsedTime} minute(s)</h4>
                    <h4>Commandes :</h4>
                    <div className='order-box'>
                      {commande.items.map((item, index) => (
                        <div key={index} className='my-order-print'>
                          <p>
                            • <strong>{item.nom}</strong> <br />
                            {item.dish_statut === "prepared_dish" && <span className="prepared-dish-label">Plat prêt</span>}
                            {item.drink_statut === "prepared_drink" && <span className="prepared-drink-label">Boisson prête</span>}
                            <br/>
                            Quantité: {item.quantity} <br/>
                            Prix unitaire: {item.prix.toFixed(2)} €
                          </p>
                        </div>
                      ))}
                    </div>
                    {commande.commentaire && (
                    <div>
                      <button 
                        className="view-comment-button" 
                        onClick={() => setSelectedComment(commande.commentaire)}
                      >
                        Voir le commentaire
                      </button>

                      {/* Popup pour afficher le commentaire */}
                      {selectedComment && (
                        <div className="comment-modal">
                          <div className="comment-modal-content">
                            <h3>Commentaire du client :</h3>
                            <p>{selectedComment}</p>
                            <button className="close-modal-button" onClick={() => setSelectedComment(null)}>Fermer</button>
                          </div>
                        </div>
                      )}
                    </div>
                    )}
                    <h4 className='total-order-price'>Prix total: {commande.items.reduce((acc, item) => acc + item.prix * item.quantity, 0).toFixed(2)} €</h4>
                    {commande.serverName && (
                      <p className='server-accepted-name'><strong>Commande acceptée par :</strong> {commande.serverName}</p>
                    )}
                    <div className="card-actions">
                      <button 
                        className="accept-action-button edit" 
                        onClick={() => handleEditOrder(commande)}
                      >
                        Modifier
                      </button>
                      <button 
                        className="accept-action-button complete" 
                        onClick={() => handleCompleteOrder(commande.clientId, commande.commandeId, commande.items, commande.prenom, commande.idclient)}
                      >
                        Terminé
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className='no-traitement'>Aucune commande acceptée à traiter.</p>
          )}

          {/* Commandes terminées */}
          <h2 className="commande-section-title">Commandes terminées</h2>
          {completedCommandes.length > 0 ? (
            <div className="commandes-container">
              {filterCommandes(completedCommandes).map((commande) => (
                <div key={commande.commandeId} className="commande-card">
                  <h3>
                    {commande.table === 'a_emporter' ? 'À Emporter' : `Table n°${commande.table}`} - {commande.idclient}
                  </h3>
                  <p className='client-name-order'>Client : {commande.prenom}</p>
                  <h4 className='order-state-time'>Durée dans cet état : <br/>{commande.elapsedTime} minute(s)</h4>
                  <h4>Commandes :</h4>
                  <div className='order-box'>
                    {commande.items.map((item, index) => (
                      <p key={index} className='my-order-print'>
                        • <strong>{item.nom}</strong> <br/>
                        Quantité: {item.quantity} <br/>
                        Prix unitaire: {item.prix.toFixed(2)} €
                      </p>
                    ))}
                  </div>
                  {commande.commentaire && (
                    <div>
                      <button 
                        className="view-comment-button" 
                        onClick={() => setSelectedComment(commande.commentaire)}
                      >
                        Voir le commentaire
                      </button>

                      {/* Popup pour afficher le commentaire */}
                      {selectedComment && (
                        <div className="comment-modal">
                          <div className="comment-modal-content">
                            <h3>Commentaire du client :</h3>
                            <p>{selectedComment}</p>
                            <button className="close-modal-button" onClick={() => setSelectedComment(null)}>Fermer</button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <h4 className='total-order-price'>Prix total: {commande.items.reduce((acc, item) => acc + item.prix * item.quantity, 0).toFixed(2)} €</h4>
                  {commande.serverName && (
                    <p className='server-accepted-name'><strong>Commande acceptée par :</strong> {commande.serverName}</p>
                  )}
                  <button className="accept-action-button served" onClick={() => handleServeOrder(commande.clientId, commande.commandeId)}>Servi</button>
                </div>
              ))}
            </div>
          ) : (
            <p className='no-traitement'>Aucune commande terminée à traiter.</p>
          )}
        </div>
      ) : (
        <p className='commande-loading'>Chargement...</p>
      )}

      {isEditModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Modifier la commande</h2>
            
            {!showAddItemForm ? (
              <>
                <div className="edit-items-container">
                  {editingItems.map((item, index) => (
                    <div key={index} className="edit-item">
                      <span>{item.nom}</span>
                      <div className="quantity-controls">
                        <button 
                          onClick={() => handleQuantityChange(index, Math.max(0, item.quantity - 1))}
                          className="quantity-button"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          min="0"
                          value={item.quantity}
                          onChange={(e) => handleQuantityChange(index, e.target.value)}
                          className="quantity-input"
                        />
                        <button 
                          onClick={() => handleQuantityChange(index, item.quantity + 1)}
                          className="quantity-button"
                        >
                          +
                        </button>
                      </div>
                      <span>{(item.prix * item.quantity).toFixed(2)} €</span>
                      
                    </div>
                  ))}
                </div>
                
                <button 
                  onClick={() => setShowAddItemForm(true)}
                  className="add-item-button"
                >
                  Ajouter un plat
                </button>
              </>
            ) : (
              <div className="add-item-form">
                <h3>Ajouter un plat</h3>
                <select 
                  value={selectedItem ? selectedItem.id : ''} 
                  onChange={(e) => {
                    const item = menuItems.find(item => item.id === e.target.value);
                    setSelectedItem(item);
                  }}
                  className="item-select"
                >
                  <option value="">Sélectionner un plat</option>
                  
                  <optgroup label="Entrées">
                    {menuItems
                      .filter(item => item.category === 'entrees')
                      .map(item => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.nom} - {item.prix.toFixed(2)} €
                          </option>
                        );
                      })}
                  </optgroup>
            
                  <optgroup label="Plats">
                    {menuItems
                      .filter(item => item.category === 'plats')
                      .map(item => (
                        <option key={item.id} value={item.id}>
                          {item.nom} - {item.prix.toFixed(2)} €
                        </option>
                      ))}
                  </optgroup>
            
                  <optgroup label="Desserts">
                    {menuItems
                      .filter(item => item.category === 'desserts')
                      .map(item => (
                        <option key={item.id} value={item.id}>
                          {item.nom} - {item.prix.toFixed(2)} €
                        </option>
                      ))}
                  </optgroup>
            
                  <optgroup label="Boissons">
                    {menuItems
                      .filter(item => item.category === 'boissons')
                      .map(item => (
                        <option key={item.id} value={item.id}>
                          {item.nom} - {item.prix.toFixed(2)} €
                        </option>
                      ))}
                  </optgroup>
                </select>
                
                <div className="add-item-actions">
                  <button 
                    onClick={() => setShowAddItemForm(false)}
                    className="cancel-button"
                  >
                    Annuler
                  </button>
                  <button 
                    onClick={handleAddItem}
                    className="save-button"
                    disabled={!selectedItem}
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            )}

            <div className="modal-total">
              Total: {editingItems.reduce((acc, item) => acc + item.prix * item.quantity, 0).toFixed(2)} €
            </div>
            
            <div className="modal-actions">
              <button onClick={() => setIsEditModalOpen(false)} className="cancel-button">
                Annuler
              </button>
              <button onClick={handleSaveEdit} className="save-button">
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServerOrder;