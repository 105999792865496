import React, { useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';

const AdminDeletePage = () => {
  const [loading, setLoading] = useState(false); // État pour l'indicateur de chargement
  const [message, setMessage] = useState(''); // Message de succès ou d'erreur

  // Fonction pour supprimer tous les documents d'une collection
  const deleteCollection = async (collectionName) => {
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      const deletePromises = querySnapshot.docs.map((docItem) =>
        deleteDoc(doc(db, collectionName, docItem.id))
      );
      await Promise.all(deletePromises); // Attendre que toutes les suppressions soient terminées
      console.log(`Tous les documents de la collection "${collectionName}" ont été supprimés.`);
    } catch (error) {
      console.error(`Erreur lors de la suppression de "${collectionName}":`, error);
      throw error; // Propager l'erreur
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer toutes les données ? Cette action est irréversible.')) {
      return; // L'utilisateur a annulé
    }

    setLoading(true);
    setMessage(''); // Réinitialiser le message

    try {
      // Supprimer les collections nécessaires
      await deleteCollection('clients');
      await deleteCollection('total_commande');
      await deleteCollection('satisfaction');

      setMessage('Toutes les données ont été supprimées avec succès.');
    } catch (error) {
      setMessage('Une erreur est survenue lors de la suppression des données.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-delete-container">
      <h1>Gestion des données</h1>
      <p>Supprimez tout le contenu des collections sensibles.</p>
      {message && <p className="admin-message">{message}</p>}
      <button 
        onClick={handleDelete} 
        disabled={loading} 
        className="delete-button"
      >
        {loading ? 'Suppression en cours...' : 'Supprimer toutes les données'}
      </button>
    </div>
  );
};

export default AdminDeletePage;
