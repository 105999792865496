import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, onSnapshot, doc, updateDoc } from 'firebase/firestore';

const ViewMenuServer = ({ menuId }) => {
  const [categorie, setCategorie] = useState('');
  const [items, setItems] = useState([]);
  const [error] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [editingField, setEditingField] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  const handleToggleActive = async (item) => {
    try {
      const updatedState = !item.actif;
      const itemRef = doc(db, `menu/${menuId}/${categorie}`, item.id);
      await updateDoc(itemRef, { actif: updatedState });
      setConfirmation(`Élément ${updatedState ? 'activé' : 'désactivé'} avec succès !`);
      triggerPopup();
    } catch (error) {
      console.error('Erreur lors du changement d\'état:', error);
    }
  };  

  const handleFieldUpdate = async (item, field, value) => {
    try {
      const itemRef = doc(db, `menu/${menuId}/${categorie}`, item.id);
      await updateDoc(itemRef, { [field]: value });
      setEditingField(null);
      setConfirmation('Champ mis à jour avec succès !');
      triggerPopup();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du champ:', error);
    }
  };  

  const triggerPopup = () => {
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 2000);
  };

  // Fonction modifiée pour écouter les changements en temps réel
  const listenToItems = useCallback((cat) => {
    const itemsCollection = collection(db, `menu/${menuId}/${cat}`);
    
    // Retourne l'unsubscribe function pour le cleanup
    return onSnapshot(itemsCollection, (snapshot) => {
      const itemList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setItems(itemList);
      setFilteredItems(itemList);
    }, (error) => {
      console.error('Erreur lors de l\'écoute des changements:', error);
    });
  }, [menuId]);

  const handleCategoryChange = useCallback((cat) => {
    setCategorie(cat);
    // Au lieu d'appeler fetchItems, on utilise listenToItems qui configure un écouteur
  }, []);

  useEffect(() => {
    if (categorie) {
      const unsubscribe = listenToItems(categorie);
      // Nettoyer l'écouteur précédent lors du changement de catégorie
      return () => unsubscribe();
    }
  }, [categorie, listenToItems]);  

  useEffect(() => {
    handleCategoryChange('entrees');
  }, [handleCategoryChange]);

  const fetchAllItems = useCallback(async () => {
    try {
      const menuCategories = ['entrees', 'plats', 'desserts', 'boissons'];
      let allItems = [];
      
      for (const cat of menuCategories) {
        const itemsCollection = collection(db, `menu/${menuId}/${cat}`);
        const itemSnapshot = await getDocs(itemsCollection);
        const itemList = itemSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          category: cat
        }));
        allItems = [...allItems, ...itemList];
      }
      
      return allItems;
    } catch (error) {
      console.error('Erreur lors de la récupération des éléments:', error);
      return [];
    }
  }, [menuId]);

  useEffect(() => {
    if (searchTerm) {
      fetchAllItems().then(allItems => {
        const filtered = allItems.filter(item => 
          item.nom.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredItems(filtered);
      });
    } else {
      setFilteredItems(items);
    }
  }, [searchTerm, items, fetchAllItems]);

  return (
    <div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Rechercher un plat..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>

      <div className="view-menu-items-buttons">
        <button onClick={() => handleCategoryChange('entrees')}>Entrées</button>
        <button onClick={() => handleCategoryChange('plats')}>Plats</button>
        <button onClick={() => handleCategoryChange('desserts')}>Desserts</button>
        <button onClick={() => handleCategoryChange('boissons')}>Boissons</button>
      </div>

      {error && <p className="error-message">{error}</p>}

      {searchTerm && filteredItems.length === 0 && (
        <div className="no-results">
          Aucun résultat trouvé pour "{searchTerm}"
        </div>
      )}

      {!searchTerm && <h3>{categorie.charAt(0).toUpperCase() + categorie.slice(1)}</h3>}

      <ul className='view-menu-items-list'>
        {(searchTerm ? filteredItems : items).map((item) => (
          <li className='cart-view' key={item.id}>
            {searchTerm && (
              <div className="item-category">
                {item.category === 'entrees' ? 'Entrées' :
                 item.category === 'plats' ? 'Plats' :
                 item.category === 'desserts' ? 'Desserts' :
                 'Boissons'}
              </div>
            )}
            <br/>
              {editingField === `nom-${item.id}` ? (
                <input
                  type="text"
                  defaultValue={item.nom}
                  onBlur={(e) => handleFieldUpdate(item, 'nom', e.target.value)}
                  autoFocus
                />
              ) : (
                <strong>{item.nom}</strong>
              )}
            <br />
            <br />
            {item.photo ? (
              <div className="image-container">
                <img
                  src={item.photo}
                  alt={item.nom}
                  className="menu-item-photo"
                />
              </div>
            ) : (
              <span
                className='add-photo-button-server'
              >
                Il n'y a pas de photos
                <br />
                <br />
              </span>
            )}
            <br />
            <span>Votre article est {item.actif ? 'actif' : 'inactif'}</span> <br />
            <br />
            <button onClick={() => handleToggleActive(item)}>
              {item.actif ? 'Désactiver' : 'Activer'}
            </button>
          </li>
        ))}
      </ul>

      {showPopup && (
        <div className={`popup-confirmation ${showPopup ? 'fade-in' : 'fade-out'}`}>
          {confirmation}
        </div>
      )}
    </div>
  );
};

export default ViewMenuServer;