import React, { useEffect, useState, useCallback } from 'react';
import { db } from '../../firebase';
import { doc, setDoc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import './HandleSupplement.css';
import { ReactComponent as MinusIcon } from '../../assets/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';

const HandleSupplement = ({ menuId }) => {
  const [categories, setCategories] = useState({});
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showAddCategoryPopup, setShowAddCategoryPopup] = useState(false);
  const [newSupplement, setNewSupplement] = useState({ name: '', category: '', price: '' });
  const [editingSupplement, setEditingSupplement] = useState(null);
  const [editingText, setEditingText] = useState('');
  const [newCategory, setNewCategory] = useState({
    name: '',
    selectionType: 'single',
    isRequired: false,
    maxElement: 1,
  });   
  const [showEditCategoryPopup, setShowEditCategoryPopup] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [editingPrice, setEditingPrice] = useState('');

  const handleEditCategory = (category) => {
    setCurrentCategory(category);
    setShowEditCategoryPopup(true);
    setNewCategory({
      name: category,
      selectionType: categories[category].selectionType
    });
  };

  const fetchSupplements = useCallback(async () => {
    try {
      const supplementRef = doc(db, 'menu', menuId);
      const docSnapshot = await getDoc(supplementRef);
      
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const supplementsData = data.suppléments || {};
        setCategories(supplementsData);
      } else {
        await setDoc(supplementRef, {
          suppléments: {}
        });
        setCategories({});
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des suppléments:', error);
    }
  }, [menuId]);

  useEffect(() => {
    fetchSupplements();
  }, [fetchSupplements]);

  const handleAddCategory = async () => {
    if (!newCategory.name.trim()) {
      alert('Veuillez entrer un nom de catégorie.');
      return;
    }
  
    const categoryNameLower = newCategory.name.trim().toLowerCase();
  
    if (Object.keys(categories).some(cat => cat.toLowerCase() === categoryNameLower)) {
      alert('Cette catégorie existe déjà.');
      return;
    }
  
    // Forcer maxElement à 1 si le type de sélection est 'single'
    const maxElement = newCategory.selectionType === 'single' ? 1 : newCategory.maxElement;
  
    try {
      const supplementRef = doc(db, 'menu', menuId);
      const docSnapshot = await getDoc(supplementRef);
  
      if (docSnapshot.exists()) {
        const newCategoryData = {
          items: [],
          selectionType: newCategory.selectionType,
          isRequired: newCategory.isRequired,
          maxElement: maxElement, // Utilisez la valeur calculée
        };
  
        await updateDoc(supplementRef, {
          [`suppléments.${newCategory.name}`]: newCategoryData
        });
  
        setCategories(prev => ({
          ...prev,
          [newCategory.name]: newCategoryData
        }));
  
        setNewCategory({ name: '', selectionType: 'single', isRequired: false, maxElement: 1 });
        setShowAddCategoryPopup(false);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout de la catégorie:", error);
    }
  };  

  const handleDeleteCategory = async (category) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer la catégorie "${category}" et tous ses suppléments ?`)) {
      try {
        // 1. Supprimer la catégorie des suppléments globaux
        const { [category]: deletedCategory, ...remainingCategories } = categories;
        setCategories(remainingCategories);
        setShowEditCategoryPopup(false);

        const supplementRef = doc(db, 'menu', menuId);
        await updateDoc(supplementRef, {
          suppléments: remainingCategories
        });

        // 2. Supprimer la catégorie de tous les plats qui l'utilisent
        const menuCategories = ['entrees', 'plats', 'desserts', 'boissons'];
        
        for (const cat of menuCategories) {
          const itemsCollection = collection(db, `menu/${menuId}/${cat}`);
          const itemSnapshot = await getDocs(itemsCollection);
          
          const updatePromises = itemSnapshot.docs.map(async (itemDoc) => {
            const itemData = itemDoc.data();
            if (itemData.suppléments && itemData.suppléments[category]) {
              const itemRef = doc(db, `menu/${menuId}/${cat}`, itemDoc.id);
              const { [category]: removed, ...remainingSupplements } = itemData.suppléments;
              await updateDoc(itemRef, {
                suppléments: remainingSupplements
              });
            }
          });

          await Promise.all(updatePromises);
        }

      } catch (error) {
        console.error('Erreur lors de la suppression de la catégorie:', error);
        fetchSupplements();
      }
    }
  };

  const handleAddSupplement = async () => {
    const { name, category, price } = newSupplement;
  
    if (!name.trim() || !category) {
      alert('Veuillez entrer un nom et sélectionner une catégorie.');
      return;
    }
  
    try {
      const supplementRef = doc(db, 'menu', menuId);
      const docSnapshot = await getDoc(supplementRef);
  
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const categoryData = data.suppléments?.[category]?.items || [];
  
        const supplementExists = categoryData.some(
          (item) => item.trim().toLowerCase() === name.trim().toLowerCase()
        );
  
        if (supplementExists) {
          alert(`Le supplément "${name}" existe déjà dans la catégorie "${category}".`);
          return;
        }
  
        let priceDisplay = price.trim().replace(',', '.');
        if (priceDisplay) {
          // Arrondir au centième
          priceDisplay = Number(priceDisplay).toFixed(2).replace('.', ',');
        }

        // Ajout du prix formaté avec virgule pour l'affichage
        const formattedSupplement = price.trim()
          ? `${name.trim()} - ${priceDisplay}€`
          : name.trim();
  
        const updatedItems = [...categoryData, formattedSupplement];
  
        await updateDoc(supplementRef, {
          [`suppléments.${category}.items`]: updatedItems,
        });
  
        setCategories((prevCategories) => ({
          ...prevCategories,
          [category]: {
            ...prevCategories[category],
            items: updatedItems,
          },
        }));
  
        setNewSupplement({ name: '', category: '', price: '' });
        setShowAddPopup(false);
      }
    } catch (error) {
      console.error('Erreur lors de l\'ajout du supplément:', error);
    }
  }; 

  const handleEditSupplement = (category, fullName) => {
    // Regex pour extraire le nom et le prix s'il y en a un
    // Cette regex accepte des prix avec virgule ou point
    const match = fullName.match(/^(.*?)\s*-\s*([\d.,]+)€$/);
    if (match) {
      // Si le format est "nom - prix€"
      const name = match[1].trim();
      const price = match[2].replace(',', '.'); // Normaliser pour l'édition
      setEditingSupplement({ name: fullName, category });
      setEditingText(name);
      setEditingPrice(price);
    } else {
      // Si pas de prix
      setEditingSupplement({ name: fullName, category });
      setEditingText(fullName);
      setEditingPrice('');
    }
  };

  const handleSaveEdit = async () => {
    if (!editingText.trim()) {
      alert('Veuillez entrer un nom.');
      return;
    }
  
    try {
      const supplementRef = doc(db, 'menu', menuId);
      const docSnapshot = await getDoc(supplementRef);
  
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const categoryData = data.suppléments?.[editingSupplement.category]?.items || [];
  
        let formattedPrice = '';
        if (editingPrice.trim()) {
          formattedPrice = Number(editingPrice.replace(',', '.')).toFixed(2).replace('.', ',');
        }
        
        const formattedSupplement = editingPrice.trim()
          ? `${editingText.trim()} - ${formattedPrice}€`
          : editingText.trim();
  
        // Remplacement correct dans la liste
        const updatedItems = categoryData.map((item) =>
          item === editingSupplement.name ? formattedSupplement : item
        );
  
        await updateDoc(supplementRef, {
          [`suppléments.${editingSupplement.category}.items`]: updatedItems,
        });
  
        // Mise à jour de l'état local
        setCategories((prevCategories) => ({
          ...prevCategories,
          [editingSupplement.category]: {
            ...prevCategories[editingSupplement.category],
            items: updatedItems,
          },
        }));
  
        // Réinitialisation des états
        setEditingSupplement(null);
        setEditingText('');
        setEditingPrice('');
      }
    } catch (error) {
      console.error('Erreur lors de la modification du supplément:', error);
    }
  };
    
  const handleDeleteSupplement = async (category, supplement) => {
    try {
      // 1. Supprimer le supplément de la catégorie globale
      const supplementRef = doc(db, 'menu', menuId);
      const docSnapshot = await getDoc(supplementRef);
      
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const categoryData = data.suppléments?.[category]?.items || [];
        const updatedItems = categoryData.filter((item) => item !== supplement);

        await updateDoc(supplementRef, {
          [`suppléments.${category}.items`]: updatedItems,
        });

        // 2. Supprimer le supplément de tous les plats qui l'utilisent
        const menuCategories = ['entrees', 'plats', 'desserts', 'boissons'];
        
        for (const cat of menuCategories) {
          const itemsCollection = collection(db, `menu/${menuId}/${cat}`);
          const itemSnapshot = await getDocs(itemsCollection);
          
          const updatePromises = itemSnapshot.docs.map(async (itemDoc) => {
            const itemData = itemDoc.data();
            if (itemData.suppléments?.[category]?.items?.includes(supplement)) {
              const itemRef = doc(db, `menu/${menuId}/${cat}`, itemDoc.id);
              const updatedSupplementItems = itemData.suppléments[category].items.filter(
                item => item !== supplement
              );
              
              // Si la catégorie n'a plus de suppléments, la supprimer complètement
              let updatedSupplements = { ...itemData.suppléments };
              if (updatedSupplementItems.length === 0) {
                const { [category]: removed, ...rest } = updatedSupplements;
                updatedSupplements = rest;
              } else {
                updatedSupplements[category] = {
                  ...updatedSupplements[category],
                  items: updatedSupplementItems
                };
              }

              await updateDoc(itemRef, {
                suppléments: updatedSupplements
              });
            }
          });

          await Promise.all(updatePromises);
        }

        setCategories((prevCategories) => ({
          ...prevCategories,
          [category]: {
            ...prevCategories[category],
            items: updatedItems
          }
        }));
      }
    } catch (error) {
      console.error('Erreur lors de la suppression du supplément:', error);
    }
  };

  // Modifier les gestionnaires de changement de type de sélection
  const handleSelectionTypeChange = async (category, newType) => {
    try {
      // 1. Mettre à jour le type de sélection dans la collection des suppléments
      const supplementRef = doc(db, 'menu', menuId);
      await updateDoc(supplementRef, {
        [`suppléments.${category}.selectionType`]: newType
      });
  
      // 2. Mettre à jour tous les produits qui utilisent cette catégorie de suppléments
      const categories = ['entrees', 'plats', 'desserts', 'boissons'];
      for (const cat of categories) {
        const itemsCollection = collection(db, `menu/${menuId}/${cat}`);
        const itemSnapshot = await getDocs(itemsCollection);
  
        const updatePromises = itemSnapshot.docs.map(async (itemDoc) => {
          const itemData = itemDoc.data();
          if (itemData.suppléments && itemData.suppléments[category]) {
            const itemRef = doc(db, `menu/${menuId}/${cat}`, itemDoc.id);
            await updateDoc(itemRef, {
              [`suppléments.${category}.selectionType`]: newType
            });
          }
        });
  
        await Promise.all(updatePromises);
      }
  
      // 3. Mettre à jour l'état local
      setCategories(prev => ({
        ...prev,
        [category]: {
          ...prev[category],
          selectionType: newType
        }
      }));
  
      // Si le type est "single", on fixe maxElement à 1 et on le verrouille
      if (newType === 'single') {
        handleMaxElementChange(category, 1); // Fixer maxElement à 1
      }
  
    } catch (error) {
      console.error('Erreur lors de la modification du type de sélection:', error);
      alert('Une erreur est survenue lors de la mise à jour du type de sélection');
    }
  };

  const handleIsRequiredChange = async (category, isRequired) => {
    try {
      // 1. Mettre à jour isRequired dans la collection des suppléments
      const supplementRef = doc(db, 'menu', menuId);
      await updateDoc(supplementRef, {
        [`suppléments.${category}.isRequired`]: isRequired
      });
  
      // 2. Mettre à jour tous les produits qui utilisent cette catégorie de suppléments
      const categories = ['entrees', 'plats', 'desserts', 'boissons'];
      for (const cat of categories) {
        const itemsCollection = collection(db, `menu/${menuId}/${cat}`);
        const itemSnapshot = await getDocs(itemsCollection);
  
        const updatePromises = itemSnapshot.docs.map(async (itemDoc) => {
          const itemData = itemDoc.data();
          if (itemData.suppléments && itemData.suppléments[category]) {
            const itemRef = doc(db, `menu/${menuId}/${cat}`, itemDoc.id);
            await updateDoc(itemRef, {
              [`suppléments.${category}.isRequired`]: isRequired
            });
          }
        });
  
        await Promise.all(updatePromises);
      }
  
      // 3. Mettre à jour l'état local
      setCategories(prev => ({
        ...prev,
        [category]: {
          ...prev[category],
          isRequired
        }
      }));
  
    } catch (error) {
      console.error('Erreur lors de la modification de isRequired:', error);
      alert('Une erreur est survenue lors de la mise à jour de l\'obligation de sélection');
    }
  };  

  const handleMaxElementChange = async (category, value) => {
    try {
      // On récupère categories depuis l'état local
      const currentCategoryData = categories[category]; // Exemple de récupération depuis l'état local
  
      // Si selectionType est "single", maxElement ne peut pas être modifié
      if (currentCategoryData?.selectionType === 'single') {
        return; // Ne rien faire si le type est "single"
      }
  
      // 1. Mettre à jour maxElement dans la collection des suppléments
      const supplementRef = doc(db, 'menu', menuId);
      await updateDoc(supplementRef, {
        [`suppléments.${category}.maxElement`]: value
      });
  
      // 2. Mettre à jour tous les produits qui utilisent cette catégorie de suppléments
      const categoriesList = ['entrees', 'plats', 'desserts', 'boissons']; // Déclaration explicite des catégories
      for (const cat of categoriesList) {
        const itemsCollection = collection(db, `menu/${menuId}/${cat}`);
        const itemSnapshot = await getDocs(itemsCollection);
  
        const updatePromises = itemSnapshot.docs.map(async (itemDoc) => {
          const itemData = itemDoc.data();
          if (itemData.suppléments && itemData.suppléments[category]) {
            const itemRef = doc(db, `menu/${menuId}/${cat}`, itemDoc.id);
            await updateDoc(itemRef, {
              [`suppléments.${category}.maxElement`]: value
            });
          }
        });
  
        await Promise.all(updatePromises);
      }
  
      // 3. Mettre à jour l'état local
      setCategories(prev => ({
        ...prev,
        [category]: {
          ...prev[category],
          maxElement: value
        }
      }));
  
    } catch (error) {
      console.error('Erreur lors de la mise à jour du maxElement:', error);
    }
  };
  
  const handleIncrement = (category) => {
    const currentCategoryData = categories[category]; // Récupère les données actuelles de la catégorie
  
    // Ne permet pas d'incrémenter si selectionType est "single"
    if (currentCategoryData?.selectionType === 'single') return;
  
    const currentMaxElement = currentCategoryData?.maxElement || 1;
    handleMaxElementChange(category, currentMaxElement + 1);
  };
  
  const handleDecrement = (category) => {
    const currentCategoryData = categories[category]; // Récupère les données actuelles de la catégorie
  
    // Ne permet pas de décrémenter si selectionType est "single"
    if (currentCategoryData?.selectionType === 'single') return;
  
    const currentMaxElement = currentCategoryData?.maxElement || 1;
    if (currentMaxElement > 1) {
      handleMaxElementChange(category, currentMaxElement - 1);
    }
  };

  return (
    <div className="supplements-container">
      <h2>Gestion des Suppléments</h2>
  
      <div className="actions-buttons">
        <button className="add-button" onClick={() => setShowAddCategoryPopup(true)}>
          <span className="desktop-text">+ Ajouter une catégorie</span>
          <span className="mobile-text">Nouvelle catégorie</span>
        </button>
        <button className="add-button" onClick={() => setShowAddPopup(true)}>
          <span className="desktop-text">+ Ajouter un supplément</span>
          <span className="mobile-text">Nouveau supplément</span>
        </button>
      </div>
  
      <div className="categories">
        {Object.entries(categories).map(([category, categoryData]) => (
          <div key={category} className="category">
            <div className="category-header">
              <div className="category-title">
                <h3>{category.charAt(0).toUpperCase() + category.slice(1)} <br/> <span className="selection-type">({categoryData.selectionType === 'single' ? 'Choix unique' : 'Choix multiple'})</span></h3>
              </div>
            </div>
            {Array.isArray(categoryData.items) && categoryData.items.length > 0 ? (
              <div className="category-items">
                {categoryData.items.map((item, index) => (
                  <div key={index} className="supplement-item">
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            ) : (
              <p className='nothing_here'>Aucun élément dans cette catégorie.</p>
            )}
            <button 
              className="edit-category-button"
              onClick={() => handleEditCategory(category)}
            >
              Modifier la catégorie
            </button>
          </div>
        ))}
      </div>
  
      {/* Popup pour ajouter une catégorie */}
      {showAddCategoryPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h3>Ajouter une catégorie</h3>
            <input
              type="text"
              placeholder="Nom de la catégorie"
              value={newCategory.name}
              onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleAddCategory();
                }
              }}
            />
            <div className="selection-type-choice">
              <label>Type de sélection:</label>
              <div>
                <label>
                  <input
                    type="radio"
                    id="single"
                    name="selectionType"
                    value="single"
                    checked={newCategory.selectionType === 'single'}
                    onChange={(e) => setNewCategory({ ...newCategory, selectionType: e.target.value })}
                  />
                  <span>Unique</span>
                </label>
                <label>
                  <input
                    type="radio"
                    id="multiple"
                    name="selectionType"
                    value="multiple"
                    checked={newCategory.selectionType === 'multiple'}
                    onChange={(e) => setNewCategory({ ...newCategory, selectionType: e.target.value })}
                  />
                  <span>Multiple</span>
                </label>
              </div>
            </div>
            <div className="is-required-choice">
              <label className='required-label'>
                <input
                  type="checkbox"
                  checked={newCategory.isRequired}
                  onChange={(e) => setNewCategory({ ...newCategory, isRequired: e.target.checked })}
                />
                <span className="required-checkbox"></span>
                Choix obligatoire
              </label>
            </div>

            <div className="max-element-choice">
              <label>Nombre maximum d'éléments:</label>
              {newCategory.selectionType === 'single' ? (
                <span className='span-max-element'>1</span> // Affiche 1 et verrouillé pour choix unique
              ) : (
                <div className="max-element-controls">
                  <button 
                    onClick={() => setNewCategory({...newCategory, maxElement: Math.max(1, newCategory.maxElement - 1)})} 
                    className="decrement-btn"
                    type="button"
                  >
                    <MinusIcon />
                  </button>
                  <span>{newCategory.maxElement}</span>
                  <button 
                    onClick={() => setNewCategory({...newCategory, maxElement: newCategory.maxElement + 1})}
                    className="increment-btn"
                    type="button"
                  >
                    <PlusIcon />
                  </button>
                </div>
              )}
            </div>

            <button onClick={handleAddCategory}>Ajouter</button>
            <button onClick={() => {
              setShowAddCategoryPopup(false);
              setNewCategory({ name: '', selectionType: 'single', isRequired: false, maxElement: 1 });
            }}>Annuler</button>
          </div>
        </div>
      )}

      {/* Popup pour ajouter un supplément */}
      {showAddPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h3>Ajouter un supplément</h3>
            <input
              type="text"
              placeholder="Nom du supplément"
              value={newSupplement.name}
              onChange={(e) => setNewSupplement({ ...newSupplement, name: e.target.value })}
            />
            <input
              type="text"
              placeholder="Prix (€)"
              value={newSupplement.price}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9.,]/g, '');
                setNewSupplement({ ...newSupplement, price: value });
              }}
            />
            <select
              value={newSupplement.category}
              onChange={(e) => setNewSupplement({ ...newSupplement, category: e.target.value })}
            >
              <option value="">Sélectionner une catégorie</option>
              {Object.keys(categories).map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
            <button onClick={handleAddSupplement}>Ajouter</button>
            <button onClick={() => {
              setShowAddPopup(false);
              setNewSupplement({ name: '', category: ''});
            }}>Annuler</button>
          </div>
        </div>
      )}
  
      {/* Popup pour modifier une catégorie */}
      {showEditCategoryPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h3>Modifier la catégorie {currentCategory}</h3>
            {currentCategory && (
              <>
                <div className="selection-type-choice">
                  <label>Type de sélection:</label>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="editSelectionType"
                        value="single"
                        checked={categories[currentCategory].selectionType === 'single'}
                        onChange={(e) => handleSelectionTypeChange(currentCategory, e.target.value)}
                      />
                      <span>Unique</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="editSelectionType"
                        value="multiple"
                        checked={categories[currentCategory].selectionType === 'multiple'}
                        onChange={(e) => handleSelectionTypeChange(currentCategory, e.target.value)}
                      />
                      <span>Multiple</span>
                    </label>
                  </div>
                </div>
                <div className="is-required-choice">
                  <label className='required-label'>
                    <input
                      type="checkbox"
                      checked={categories[currentCategory]?.isRequired || false}
                      onChange={(e) => handleIsRequiredChange(currentCategory, e.target.checked)}
                    />
                    <span className="required-checkbox"></span>
                    Choix obligatoire
                  </label>
                </div>
                <div className="max-element-choice">
                  <label>Nombre maximum d'éléments:</label>
                  {categories[currentCategory]?.selectionType === 'single' ? (
                    <span className='span-max-element'>1</span> // Affiche 1 et verrouillé pour choix unique
                  ) : (
                    <div className="max-element-controls">
                      <button onClick={() => handleDecrement(currentCategory)} className="decrement-btn">
                        <MinusIcon />
                      </button>
                      <span>{categories[currentCategory]?.maxElement || 1}</span>
                      <button onClick={() => handleIncrement(currentCategory)} className="increment-btn">
                        <PlusIcon />
                      </button>
                    </div>
                  )}
                </div>
                <ul className="category-items">
                  {categories[currentCategory].items.map((item, index) => (
                    <li key={index}>
                      {editingSupplement && editingSupplement.name === item ? (
                        <div>
                          <input
                            type="text"
                            value={editingText}
                            onChange={(e) => setEditingText(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSaveEdit();
                              } else if (e.key === 'Escape') {
                                setEditingSupplement(null);
                                setEditingText('');
                              }
                            }}
                            autoFocus
                          />
                          <input
                            type="text"
                            placeholder="Prix (€)"
                            value={editingPrice}
                            onChange={(e) => {
                              // Accepter uniquement des chiffres et une virgule/point
                              const value = e.target.value.replace(/[^0-9.,]/g, '');
                              setEditingPrice(value);
                            }}
                          />
                          <button onClick={handleSaveEdit}>Sauvegarder</button>
                          <button onClick={() => {
                            setEditingSupplement(null);
                            setEditingText('');
                          }}>Annuler</button>
                        </div>
                      ) : (
                        <div>
                          <span onClick={() => handleEditSupplement(currentCategory, item)}>{item}</span>
                          <button className="delete-button" onClick={() => handleDeleteSupplement(currentCategory, item)}>Supprimer</button>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
                <div className="button-group">
                  <button className="delete-button" onClick={() => handleDeleteCategory(currentCategory)}>Supprimer la catégorie</button>
                  <button onClick={() => setShowEditCategoryPopup(false)}>Fermer</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HandleSupplement;