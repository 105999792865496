import React, { useEffect, useState } from 'react';
import { auth } from '../../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { db } from '../../firebase';
import { doc, setDoc, collection, deleteDoc, onSnapshot } from 'firebase/firestore';
import './admin.css';

const AddServerForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const serversCollection = collection(db, 'identifications');
    
    const unsubscribe = onSnapshot(serversCollection, (snapshot) => {
      const serverList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServers(serverList);
    }, (error) => {
      console.error("Erreur lors de la récupération des serveurs : ", error);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      if (selectedServer) {
        // Mise à jour uniquement du nom et du prénom
        const serverDoc = doc(db, 'identifications', selectedServer.id);
        await setDoc(serverDoc, {
          ...selectedServer, // Conserver les données existantes
          firstName,
          lastName,
        });

        setSuccess('Nom et prénom mis à jour avec succès !');
      } else {
        // Ajouter un nouveau serveur
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        await setDoc(doc(db, 'identifications', user.uid), {
          email: user.email,
          firstName,
          lastName,
          role: 'server',
          createdAt: new Date(),
        });
        setSuccess('Serveur ajouté avec succès !');
      }

      // Réinitialiser les champs
      setEmail('');
      setPassword('');
      setFirstName('');
      setLastName('');
      setSelectedServer(null);
    } catch (error) {
      setError('Erreur lors de l\'ajout ou de la mise à jour du serveur : ' + error.message);
    }
  };

  const handleEdit = (server) => {
    setFirstName(server.firstName);
    setLastName(server.lastName);
    setSelectedServer(server);
  };

  const handleDelete = async (serverId) => {
    try {
      // Appeler le backend pour supprimer l'utilisateur dans Firebase Authentication
      const response = await fetch('https://skaneat-app.vercel.app/delete-user', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: serverId }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erreur lors de la suppression de l’utilisateur.');
      }
  
      await deleteDoc(doc(db, 'identifications', serverId));
      await deleteDoc(doc(db, 'horaires', serverId));
  
      setSuccess('Serveur supprimé avec succès !');
    } catch (error) {
      setError('Erreur lors de la suppression du serveur : ' + error.message);
    }
  };  

  const exportToCSV = () => {
    const today = new Date().toLocaleDateString('fr-FR');
    const headers = ['Nom', 'Prenom', 'Email', 'Role', 'Date de creation'];
    
    // Formater les données
    const csvData = servers
      .filter((server) => server.role === 'server')
      .map((server) => {
        const createdAt = server.createdAt?.toDate 
          ? server.createdAt.toDate().toLocaleDateString('fr-FR')
          : 'Non specifie';
        
        return [
          server.firstName,
          server.lastName,
          server.email,
          server.role,
          createdAt
        ].join(';');
      });
    
    // Assembler le contenu CSV
    const csvContent = [
      headers.join(';'),
      ...csvData
    ].join('\n');
    
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    
    link.setAttribute('href', url);
    link.setAttribute('download', `serveurs_${today.replace(/\//g, '-')}.csv`);
    link.style.visibility = 'hidden';
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="server-form-container">
      <h2>{selectedServer ? 'Modifier un serveur' : 'Ajouter un serveur'}</h2>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
      
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nom :</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Prénom :</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>

        {!selectedServer && (
          <>
            <div>
              <label>Email du serveur :</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Mot de passe du serveur :</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </>
        )}

        <button type="submit">
          {selectedServer ? 'Mettre à jour le serveur' : 'Ajouter un serveur'}
        </button>
      </form>

      <div className="server-list-container">
        <div className="list-header">
          <h3>Serveurs Enregistrés :</h3>
          <button onClick={exportToCSV} className="export-button">
            Exporter en CSV
          </button>
        </div>
        <ul>
          {servers
            .filter((server) => server.role === 'server') // Filtre les serveurs avec le rôle 'server'
            .map((server) => (
              <li key={server.id}>
                {server.firstName} {server.lastName} - {server.email}
                <div>
                  <button onClick={() => handleEdit(server)}>Modifier</button>
                  <button onClick={() => handleDelete(server.id)}>Supprimer</button>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default AddServerForm;
