import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, query, where, onSnapshot, doc, getDocs, deleteDoc, updateDoc, increment } from 'firebase/firestore';
import './MyPaiement.css';

const MyPaiement = () => {
  const [clients, setClients] = useState([]); // Liste des clients avec paiement (tous les statuts)
  const [loading, setLoading] = useState(true); // Indicateur de chargement
  const [showModal, setShowModal] = useState(false);
  const [currentClientId, setCurrentClientId] = useState(null);
  const [selectedPayments, setSelectedPayments] = useState({
    espece: false,
    cb_comptoir: false,
    ticket_resto: false
  });
  const [paymentAmounts, setPaymentAmounts] = useState({
    espece: '',
    cb_comptoir: '',
    ticket_resto: ''
  });

  useEffect(() => {
    // Récupérer les clients avec paiement (tous les statuts)
    const fetchClients = async () => {
      try {
        const clientsCollection = collection(db, 'clients');
        const q = query(clientsCollection, where('paiement', '!=', null));  // Récupérer tous les clients ayant un objet paiement

        onSnapshot(q, async (querySnapshot) => {
          const clientsData = await Promise.all(
            querySnapshot.docs.map(async (docSnapshot) => {
              const clientData = docSnapshot.data();
              const clientId = docSnapshot.id;
        
              // Récupérer les détails de la commande pour ce client
              const commandesCollection = collection(db, `clients/${clientId}/commande`);
              const commandesSnapshot = await getDocs(commandesCollection);
              let totalCommande = 0;
              let items = [];
        
              commandesSnapshot.forEach((doc) => {
                const commandeData = doc.data();
                items = [...items, ...commandeData.items];
                totalCommande += commandeData.items.reduce((acc, item) => acc + item.prix * item.quantity, 0);
              });

              const tableLabel = clientData.table === 'a_emporter' ? 'À Emporter' : `Numéro de Table ${clientData.table}`;
        
              return {
                id: clientId,
                prenom: clientData.prenom,
                table: tableLabel,
                email: clientData.email,
                idclient: clientData.idclient,
                paiement: clientData.paiement,
                items,
                totalCommande,
              };
            })
          );
          setClients(clientsData);
          setLoading(false);
        });        
      } catch (error) {
        console.error('Erreur lors de la récupération des clients:', error);
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const handlePaiementEffectue = async () => {
    if (!currentClientId) return;

    // Vérifier que la somme des montants correspond au total
    const selectedPaymentMethods = Object.entries(selectedPayments)
      .filter(([_, isSelected]) => isSelected)
      .map(([method]) => method);

    const totalAmount = selectedPaymentMethods.reduce(
      (sum, method) => sum + parseFloat(Number(paymentAmounts[method]).toFixed(2)),
      0
    );

    const clientData = clients.find(client => client.id === currentClientId);
    const commandeTotal = parseFloat(clientData.totalCommande.toFixed(2));
    
    if (Math.abs(totalAmount - commandeTotal) > 0.01) {
      alert('La somme des montants ne correspond pas au total de la commande');
      return;
    }

    try {
      const clientRef = doc(db, 'clients', currentClientId);
      await updateDoc(clientRef, {
        'paiement.status': 'payé',
        'paiement.methods': selectedPaymentMethods.map(method => ({
          type: method,
          amount: parseFloat(Number(paymentAmounts[method]).toFixed(2))
        }))
      });

      await updatePaymentCounters();
      setShowModal(false);
      setSelectedPayments({
        espece: false,
        cb_comptoir: false,
        ticket_resto: false
      });
      setPaymentAmounts({
        espece: '',
        cb_comptoir: '',
        ticket_resto: ''
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour du paiement:', error);
    }
  };

  // Fonction pour imprimer le ticket
  const handleImprimerTicket = (client) => {
    const ticketContent = `
      Résumé de commande :
      --------------------
      Client: ${client.prenom}
      Numéro de Table: ${client.table}
      Numéro de Commande: ${client.idclient}
      Email: ${client.email}
      
      Détails de la commande :
      --------------------
      ${client.items && client.items.length > 0 ? client.items.map((item, index) => `
        ${item.nom} - Quantité: ${item.quantity} - Prix Unitaire: ${item.prix.toFixed(2)} €
        Prix Total: ${(item.prix * item.quantity).toFixed(2)} €
      `).join('\n') : 'Aucun article dans la commande.'}
      
      Total de la commande: ${client.totalCommande.toFixed(2)} €
      
      Merci de votre commande !`;
    
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Ticket de caisse</title></head><body>');
    printWindow.document.write(`<pre>${ticketContent}</pre>`);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  if (loading) {
    return <div>Chargement des clients...</div>;
  }

  const handleDeleteCommande = async (clientId) => {
    try {
      const clientRef = doc(db, 'clients', clientId);
      await deleteDoc(clientRef);
      alert('Commande supprimée de la base de données.');
    } catch (error) {
      console.error('Erreur lors de la suppression de la commande:', error);
    }
  };  

  const updatePaymentCounters = async () => {
    try {
      const paiementRef = doc(db, 'paiement', 'paiement');
      
      // Créer un objet pour les mises à jour
      const updates = {};
      
      if (selectedPayments.espece) {
        updates.espece = increment(1);
      }
      if (selectedPayments.cb_comptoir) {
        updates.cb_comptoir = increment(1);
      }
      if (selectedPayments.ticket_resto) {
        updates.ticket_resto = increment(1);
      }
      
      // Vérifier si au moins un mode de paiement est sélectionné
      if (Object.keys(updates).length > 0) {
        await updateDoc(paiementRef, updates);
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour des compteurs:', error);
    }
  };

  const openPaymentModal = (clientId) => {
    setCurrentClientId(clientId);
    setShowModal(true);
  };

  const PaymentModal = () => {
    const clientData = clients.find(client => client.id === currentClientId);
    const totalAmount = clientData?.totalCommande || 0;
    
    const [localPaymentAmounts, setLocalPaymentAmounts] = useState({
      espece: '',
      cb_comptoir: '',
      ticket_resto: ''
    });
    
    const selectedCount = Object.values(selectedPayments).filter(Boolean).length;
    
    // Calcul du reste à payer
    const totalEntered = Object.entries(selectedPayments)
      .filter(([_, isSelected]) => isSelected)
      .reduce((sum, [method]) => {
        const amount = parseFloat(localPaymentAmounts[method]) || 0;
        return sum + parseFloat(amount.toFixed(2));
      }, 0);

    const remainingAmount = parseFloat((totalAmount - totalEntered).toFixed(2));

    const handleAmountChange = (key, value) => {
      setLocalPaymentAmounts(prev => ({
        ...prev,
        [key]: value
      }));
    };

    const handleValidate = async () => {
      const hasSelectedPayment = Object.values(selectedPayments).some(value => value);
      
      if (!hasSelectedPayment) {
        alert('Veuillez sélectionner au moins un mode de paiement');
        return;
      }

      // Si un seul moyen de paiement est sélectionné
      if (selectedCount === 1) {
        const selectedMethod = Object.entries(selectedPayments).find(([_, isSelected]) => isSelected)[0];
        const updatedAmounts = {
          espece: '',
          cb_comptoir: '',
          ticket_resto: '',
          [selectedMethod]: totalAmount.toFixed(2)
        };
        
        try {
          const clientRef = doc(db, 'clients', currentClientId);
          await updateDoc(clientRef, {
            'paiement.status': 'payé',
            'paiement.methods': [{
              type: selectedMethod,
              amount: totalAmount
            }]
          });

          await updatePaymentCounters();
          setShowModal(false);
          setSelectedPayments({
            espece: false,
            cb_comptoir: false,
            ticket_resto: false
          });
          setPaymentAmounts({
            espece: '',
            cb_comptoir: '',
            ticket_resto: ''
          });
        } catch (error) {
          console.error('Erreur lors de la mise à jour du paiement:', error);
        }
        return;
      }

      // Pour plusieurs moyens de paiement, vérifier que la somme correspond
      const totalEntered = Object.entries(selectedPayments)
        .filter(([_, isSelected]) => isSelected)
        .reduce((sum, [method]) => {
          const amount = parseFloat(localPaymentAmounts[method]) || 0;
          return sum + parseFloat(amount.toFixed(2));
        }, 0);

      // Comparaison avec une tolérance pour les erreurs d'arrondi
      if (Math.abs(totalEntered - totalAmount) > 0.01) {
        alert('La somme des montants ne correspond pas au total de la commande');
        return;
      }

      try {
        const clientRef = doc(db, 'clients', currentClientId);
        await updateDoc(clientRef, {
          'paiement.status': 'payé',
          'paiement.methods': Object.entries(selectedPayments)
            .filter(([_, isSelected]) => isSelected)
            .map(([method]) => ({
              type: method,
              amount: parseFloat(Number(localPaymentAmounts[method]).toFixed(2))
            }))
        });

        await updatePaymentCounters();
        setShowModal(false);
        setSelectedPayments({
          espece: false,
          cb_comptoir: false,
          ticket_resto: false
        });
        setPaymentAmounts({
          espece: '',
          cb_comptoir: '',
          ticket_resto: ''
        });
      } catch (error) {
        console.error('Erreur lors de la mise à jour du paiement:', error);
      }
    };

    return (
      <div className="modal-overlay-popup">
        <div className="modal-content-popup">
          <h2>Sélectionner le(s) mode(s) de paiement</h2>
          <p>Montant total : {totalAmount.toFixed(2)}€</p>
          <p>Reste à payer : {remainingAmount.toFixed(2)}€</p>
          
          <div className="payment-options">
            {[
              { key: 'espece', label: 'Espèces' },
              { key: 'cb_comptoir', label: 'CB' },
              { key: 'ticket_resto', label: 'Ticket Restaurant' }
            ].map(({ key, label }) => (
              <div key={key} className="payment-method-container">
                <button 
                  className={`payment-button ${selectedPayments[key] ? 'selected' : ''}`}
                  onClick={() => setSelectedPayments(prev => ({
                    ...prev,
                    [key]: !prev[key]
                  }))}
                  type="button"
                >
                  {label}
                </button>
                {selectedPayments[key] && selectedCount > 1 && (
                  <input
                    type="number"
                    step="0.01"
                    min="0"
                    value={localPaymentAmounts[key]}
                    onChange={(e) => handleAmountChange(key, e.target.value)}
                    className="payment-amount-input"
                    placeholder="Montant"
                  />
                )}
              </div>
            ))}
          </div>

          <div className="modal-buttons">
            <button 
              onClick={handleValidate}
              type="button"
            >
              Valider
            </button>
            <button 
              onClick={() => {
                setShowModal(false);
                setSelectedPayments({
                  espece: false,
                  cb_comptoir: false,
                  ticket_resto: false
                });
                setLocalPaymentAmounts({
                  espece: '',
                  cb_comptoir: '',
                  ticket_resto: ''
                });
              }}
              type="button"
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mypaiement">
      <h1 className='mypaiement-title'>Liste des Paiements</h1>
      <div className="mypaiement-container">
        {clients.length > 0 ? (
          clients.map((client) => (
            <div key={client.id} className="client-card">
              <h2>Client : {client.prenom}</h2>
              <p className='paiement-table'>Commande : {client.idclient}</p>
              <p> {client.table}</p>
              <p>Email : {client.email}</p>
              <p>Status de paiement : {client.paiement.status}</p>
              
              <h3 className='details-card-command'>Détails de la commande :</h3>
              {client.items && client.items.length > 0 ? (
                <ul>
                  {client.items.map((item, index) => (
                    <li key={index}>
                      <strong>{item.nom}</strong> <br/>
                      Quantité : {item.quantity} <br/>
                      Prix Unitaire: {item.prix.toFixed(2)} € <br/>
                      Prix Total: {(item.prix * item.quantity).toFixed(2)} €
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Aucun article dans la commande.</p>
              )}
              
              <h3 className='total-card-command'>Total de la commande <br />{client.totalCommande.toFixed(2)} €</h3>

              <div className='accept-button'>
                {client.paiement.status === 'en_attente' && (
                  <button onClick={() => openPaymentModal(client.id)}>Marquer comme payé</button>
                )}
                {client.paiement.status === 'payé' && (
                <button className='delete-button-client' onClick={() => handleDeleteCommande(client.id)}>Supprimer la commande</button>
                )}
                <button onClick={() => handleImprimerTicket(client)}>Imprimer le ticket</button>
              </div>
            </div>
          ))
        ) : (
          <p>Aucune commande trouvée.</p>
        )}
      </div>
      {showModal && <PaymentModal />}
    </div>
  );
};

export default MyPaiement;
