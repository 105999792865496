import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebase'; // Firebase Firestore import
import { collection, query, where, updateDoc, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth'; // Importer signOut
import { auth } from '../../firebase';
import './ServerSchedule.css';

const ServerSchedule = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState(null); // État pour stocker l'emploi du temps de l'utilisateur
  const [totalHours, setTotalHours] = useState(0); // Total des heures travaillées
  
  // Vérifier si l'utilisateur est connecté
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate('/login'); // Rediriger vers la page de connexion si non connecté
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const formatHours = (hours) => {
    const fullHours = Math.floor(hours); // Partie entière des heures
    const minutes = Math.round((hours - fullHours) * 60); // Partie décimale convertie en minutes
    return `${fullHours}h${minutes.toString().padStart(2, '0')}`; // Format h:mm avec zéro-padding
  };

  // Récupérer l'emploi du temps de l'utilisateur connecté
   useEffect(() => {
    const fetchSchedule = async () => {
      if (user) {
        try {
          const scheduleRef = collection(db, 'horaires');
          const q = query(scheduleRef, where('employeeId', '==', user.uid));
          const querySnapshot = await getDocs(q);
  
          querySnapshot.forEach((doc) => {
            setSchedule(doc.data().schedule); // Extraire l'emploi du temps
          });
        } catch (error) {
          console.error("Erreur lors de la récupération de l'emploi du temps :", error);
        }
      }
    };
  
    fetchSchedule();
  }, [user]);

  const calculateTotalHours = useCallback(async () => {
    if (!schedule || !user) return;
  
    const currentDate = new Date();
    const currentDay = currentDate.getDate(); // Jour actuel du mois
  
    const daysInWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const hoursWorked = [];
  
    // On va vérifier chaque jour de la semaine jusqu'au jour actuel du mois
    for (let i = 0; i < currentDay; i++) {
      const dayOfWeek = daysInWeek[i % 7]; // Calculer le jour de la semaine pour la date
      const daySchedule = schedule[dayOfWeek];
  
      if (daySchedule && daySchedule !== "Ne travaille pas") {
        const periods = daySchedule.split(" / "); // Diviser les périodes par "/"
        let dailyHours = 0;
      
        periods.forEach((period) => {
          const [start, end] = period.split(" - ");
          const startTime = new Date(`2023-12-01T${start}:00`);
          const endTime = new Date(`2023-12-01T${end}:00`);
          const hours = (endTime - startTime) / 1000 / 60 / 60; // Conversion en heures
          dailyHours += hours;
        });
      
        hoursWorked.push(dailyHours); // Ajouter les heures du jour au tableau
      }      
    }
  
    let total = hoursWorked.reduce((acc, hours) => acc + hours, 0); // Calcul du total
  
    // Ajouter les heures supplémentaires depuis la base de données
    try {
      const scheduleRef = collection(db, 'horaires');
      const q = query(scheduleRef, where('employeeId', '==', user.uid));
      const querySnapshot = await getDocs(q);
  
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.heure_supp) {
          total += parseFloat(data.heure_supp);
        }
  
        // Mettre à jour le total dans la base de données
        updateDoc(doc.ref, { total_hours: total });
      });
  
      setTotalHours(total);
    } catch (error) {
      console.error("Erreur lors de la récupération/mise à jour des heures supplémentaires :", error);
    }
  }, [schedule, user]); // `calculateTotalHours` dépend de `schedule` et `user`  

  useEffect(() => {
    calculateTotalHours(); // Appeler le calcul des heures dès que l'emploi du temps est récupéré
  }, [schedule, calculateTotalHours]);

  const renderSchedule = () => {
    if (!schedule) return <p>Chargement de l'emploi du temps...</p>;

    const daysInFrench = {
      Sunday: "Dimanche",
      Monday: "Lundi",
      Tuesday: "Mardi",
      Wednesday: "Mercredi",
      Thursday: "Jeudi",
      Friday: "Vendredi",
      Saturday: "Samedi"
    };

    const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    return (
      <table className="em-schedule-table">
        <thead>
          <tr>
            {dayNames.map((day) => (
              <th key={day}>{daysInFrench[day]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {dayNames.map((day) => (
              <td key={day}>
                {schedule[day] === 'Ne travaille pas' ? 'Ne travaille pas' : schedule[day]}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div className="schedule-interface-container">
      <h1 className="schedule-interface-title">Interface Serveur</h1>
  
      <section className="schedule-class">
        <h2 className="schedule-title">Emploi du temps</h2>
        <div className="schedule-container">
          {renderSchedule()}
        </div>
      </section>
  
      <section className="schedule-section">
        <h2 className="schedule-section-title">Total des heures travaillées ce mois</h2>
        <div className="schedule-total-hours-card">
          <p>Total des heures travaillées : <span className="schedule-section-total-hours">{formatHours(totalHours)}</span> heures</p>
        </div>
      </section>
    </div>
  );  
};

export default ServerSchedule;