import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_VAR1, 
  authDomain: process.env.REACT_APP_VAR2,
  projectId: process.env.REACT_APP_VAR3,
  storageBucket: process.env.REACT_APP_VAR4,
  messagingSenderId: process.env.REACT_APP_VAR5,
  appId: process.env.REACT_APP_VAR6,
  measurementId: process.env.REACT_APP_VAR7,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
